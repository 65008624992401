import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./app/scrollToTop";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./utils/authConfig";
import { userActivityLogout } from './utils/userActivityLogout';
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const AppWithUserInactivity = userActivityLogout(App);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <MsalProvider instance={msalInstance}>
                <AppWithUserInactivity msalInstance={msalInstance} />
            </MsalProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
