import { LogLevel } from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_APM_CLIENT_ID || "asfa",
    authority: process.env.REACT_APP_APM_AUTHORITY || "asfa",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
    },
  },
};

// https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
export const loginRequest = {
  scopes: [],
};
