import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import { Bar } from 'react-chartjs-2';
import PageSubTitle from '../../component/Common/PageSubTitle'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if ((!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);
    
        return () => clearTimeout(timeoutId);
    }, [accounts, history]);


    const timelySubmissonByHealthPlanChartData = {
        labels: ['United HealthCare', 'Molina HealthCare', 'Nabraska Total Care'],
        datasets: [
            {
                label: 'Count',
                data: [300, 50, 100],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const timelySubmissonByHealthPlanBarChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false
            },
            title: {
                display: true,
                text: 'Timely Submission by Health Plan',
            },
        },
    }
    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Contract Reports Submitted" />
                </div>
            </div>

            <div className="form-layout"> 
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '20px'  }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                    <div style={{ flex: '1 1 100%', maxWidth: '500px', margin: '0 1px', border: '1px solid #ccc', borderRadius: '5px', padding: '1px', marginInline:'10px' }}>
                        <Bar data={timelySubmissonByHealthPlanChartData} options={timelySubmissonByHealthPlanBarChartOptions} />
                    </div>
                    <div style={{ flex: '1 1 100%', maxWidth: '500px', margin: '0 1px', border: '1px solid #ccc', borderRadius: '5px', padding: '1px' }}>
                        <Bar data={timelySubmissonByHealthPlanChartData} options={timelySubmissonByHealthPlanBarChartOptions} />
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </>
    );
}
export default Dashboard;