import React, { useEffect, useRef, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";

export const userActivityLogout = (Component: React.ComponentType<any>) => {
    return (props: any) => {
        const timerRef = useRef<number | undefined>(undefined);
        const { instance, accounts } = useMsal();
        const [account] = accounts;
        const alertRef = useRef(false);

        const handleLogout = useCallback(() => {
            if (account) {
                instance.logoutRedirect({
                    account,
                    postLogoutRedirectUri: "/sign-in",
                });
                alertRef.current = false;
            }
        }, [instance, account]);

        const handleAlert = useCallback(async () => {
            if (account && !alertRef.current) {
                alertRef.current = true;
                alert('Your session has expired. You will be redirected to the login page.')
                handleLogout();
            }
        }, [handleLogout, account]);

        useEffect(() => {
            const resetTimer = () => {
                if (timerRef.current !== undefined) {
                    clearTimeout(timerRef.current);
                }
                // timerRef.current = window.setTimeout(handleAlert, 600000); //10 minutes
            };

            const events = ['mousemove', 'keydown', 'scroll', 'click'];

            events.forEach(event => {
                window.addEventListener(event, resetTimer);
            });

            resetTimer();

            // Cleanup function
            return () => {
                if (timerRef.current !== undefined) {
                    clearTimeout(timerRef.current);
                }
                events.forEach(event => {
                    window.removeEventListener(event, resetTimer);
                });
            };
        }, [handleAlert]);

        return <Component {...props} />;
    };
};