import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory} from 'react-router-dom'

const UserManagement = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if ((!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);
    
        return () => clearTimeout(timeoutId);
    }, [accounts, history]);
      
    return (
        <div className="flex flex-col overflow-x-hidden h-screen ">
            <Navbar />
            <BackButton />
            <div className="flex-grow">
                <p style={{textAlign: 'center'}}>User Management Page</p>
            </div>
            <Footer />
        </div>
    );
}
export default UserManagement;