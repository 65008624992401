import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reference-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _ from 'lodash'

const Reference = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const serachDropdownChoices1 = [
        {
            label: 'Review Group',
            value: 'reviewGroup',
        },
        {
            label: 'Report Name',
            value: 'reportName',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Review Group',
            value: 'reviewGroup',
        },
        {
            label: 'Report Name',
            value: 'reportName',
        }
    ]

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ReferenceList {
        reportName: string;
        reportGroup: string;
        frequency: string;
    }

    // Example initialData for useState
    const initialData: ReferenceList[] = [
        { reportName: 'Past Due Reminder1', reportGroup: "Report Due Not Submitted as per schedule-1", frequency: "Pharmacy Claims Report-1" },
        { reportName: 'Past Due Reminder2', reportGroup: "Report Due Not Submitted as per schedule-2", frequency: "Pharmacy Claims Report-2" },
        { reportName: 'Past Due Reminder3', reportGroup: "Report Due Not Submitted as per schedule-3", frequency: "Pharmacy Claims Report-3" },
    ];
    const gridStyle = { minHeight: 550, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    let [filterData, setFilterData] = useState<ReferenceList[]>(initialData);
    const [selectedOption1, setSelectedOption1] = useState<any>(null);
    const [selectedOption2, setSelectedOption2] = useState<any>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };
    const referenceListHeaders: any[] = [
        {
            name: 'reportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
        },
        {
            name: 'reportGroup',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Group
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'frequency',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
        },
        {
            name: 'reviewStatus',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Review Status
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: any) => {
                const handleColumnClick = () => {
                    history.push({
                        // pathname: '/contract-report-details',
                        state: { recordData: data }
                    });
                };

                return (
                    <Button className='OkButton' onClick={handleColumnClick}>Details</Button>
                );
            },
        }
    ]

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text(null);
        setFilter2Text(null);
        setSelectedOption1(null);
        setSelectedOption2(null);
        // Ensure getAllBusinessAppList is defined and used correctly
        // getAllBusinessAppList(true, currentStatusDropdownState);
    }

    function handleSearch() {
        // savedFilters.businessApplicationMain = {
        //   selectedOption1,
        //   selectedOption2,
        //   filter1Text,
        //   filter2Text,
        // } as any
        // getAllBusinessAppList(false, currentStatusDropdownState)
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Reference Page" />
                </div>
            </div>

            <div className="form-layout">
                <div className="external-filters">
                    <div className="filters-row" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                        <div className="external-filters-elem" style={{ maxWidth: '220px' }}>
                            <Dropdown
                                choices={serachDropdownChoices1}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="filterCriteria1"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption1 ? selectedOption1 : ''}
                                onChange={handleFilterChange1}
                            />
                        </div>
                        <div className="external-filters-elem" style={{ maxWidth: '250px' }}>
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 1"
                                value={filter1Text || ''}
                                onChange={handleFilterText1}
                                small={true}
                            />
                        </div>
                        <div className="external-filters-elem" style={{ maxWidth: '220px' }}>
                            <Dropdown
                                choices={serachDropdownChoices2}
                                emptyChoiceLabel="Select Filter Criteria 2"
                                name="filterCriteria2"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption2 ? selectedOption2 : ''}
                                onChange={handleFilterChange2}
                            />
                        </div>
                        <div className="external-filters-elem" style={{ maxWidth: '250px' }}>
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 2"
                                value={filter2Text || ''}
                                onChange={handleFilterText2}
                                small={true}
                            />
                        </div>
                        <div className="external-filters-action" style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text))
                                }
                                className="OkButton"
                                onClick={() => handleClear()}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text)
                                    )
                                }
                                className="OkButton"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                </div>

                <ReactDataGrid
                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                    idProperty="key"
                    style={gridStyle}
                    pagination
                    columns={referenceListHeaders}
                    dataSource={filterData}
                    sortable={true}
                    // onFilterValueChange={handleFilterChangeGrid}
                    defaultLimit={10}
                    // defaultFilterValue={filterValue}
                    // enableColumnAutosize
                    rowHeight={50}
                    // onRowClick={({ data }) => handleColumnClick(data)}
                    // className="pointer-cursor-row"
                    enableColumnAutosize={true}
                    handle={setGridRef}
                />
            </div>
            <Footer />
        </>
    );
}
export default Reference;