import React, { useRef, useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './upload-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';

const UploadReportInventory = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [fileName, setFileName] = useState('');
    const [selectedValues, setSelectedValues] = useState<number[]>([])
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedMCOPlanDropdown, setSelectedMCOPlanDropdown] = useState('');
    const [defaultsSet, setDefaultsSet] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const [fileValue, setFileValue] = useState<FileWithMetadata[]>([]);
    const [removingFileName, setRemovingFileName] = useState('');

    const [uploadNewReport, setUploadNewReport] = useState<any>({
        mcoName: '',
        reportName: '',
        referenceNumber: '',
        frequency: '',
        channel: 'WEB',
        reportType: '',
        startDate: '' || moment(new Date()).format('YYYY-MM-DD'),
        endDate: '' || moment().add(1, 'year').format('YYYY-MM-DD'),
        dueDate: '' || moment(new Date()).format('YYYY-MM-DD'),
        submissionType: '',
        submissionDate: '' || moment(new Date()).format('MM/DD/YYYY hh:mm A'),
        reportUploadChecklist: '',
        file: '',
        userComments: '',
        // submissinStatus: 'Submitted',
        currentGroup: 'MC Control Group',
        currentSubmission: '1',
        reviewStatus: 'In Review',
        lastUpdatedBy: accounts[0]?.name || 'MCO User',
        lastUpdatedDate: moment(new Date()).format('MM/DD/YYYY hh:mm A'),
        fileType: '',
        uploadFiles: [],
        description: 'Data regarding the grievances received by the MCOs.',
        submissionNumber: '1',
        submissionStatus: 'Scheduled',
        period: ''
    });

    interface FileWithMetadata {
        id: number;
        name: string;
        type: string;
        uploaded: boolean;
    }

    const fileInputRef = useRef<HTMLInputElement>(null);
    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType !== null) {

                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    mcoName: 'Molina',
                    reportType: 'Standard',
                    referenceNumber: 'NE_MC_M_0021',
                    reportName: 'GrievanceLog',
                    frequency: 'Monthly',
                    startDate: '2024-06-01',
                    endDate: '2024-06-30',
                    dueDate: '2024-07-15',
                    period: '06/01/2024-06/30/2024',
                }));

            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [userType]);

    function validateForm(): void {

        if (
            !uploadNewReport.mcoName ||
            !uploadNewReport.reportName ||
            !uploadNewReport.referenceNumber ||
            !uploadNewReport.frequency ||
            !uploadNewReport.reportType ||
            !uploadNewReport.startDate ||
            !uploadNewReport.endDate ||
            !uploadNewReport.submissionDate ||
            // !uploadNewReport.reportUploadChecklist ||
            !uploadNewReport.dueDate

        ) {
            setIsFormValid(false)
        } else {
            setIsFormValid(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [uploadNewReport])

    useEffect(() => {
        if (!defaultsSet) {
            if (selectedMCOPlanDropdown === 'unitedHealth') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    reportType: 'ondemand',
                    reportName: 'Bi-MonthlyTipsReport',
                    frequency: 'Monthly',
                    startDate: '2024-07-16',
                    endDate: '2024-07-31',
                    dueDate: '2008-01-20'
                }));
                setDefaultsSet(true);
            } else if (selectedMCOPlanDropdown === 'nebraskaTotalPlan') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    reportType: 'ondemand',
                    reportName: 'AppealLog',
                    frequency: 'Monthly',
                    startDate: '2024-06-01',
                    endDate: '2024-06-30',
                    dueDate: '2024-07-15'
                }));
                setDefaultsSet(true);
            } else if (selectedMCOPlanDropdown === 'Molina') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    reportType: 'ondemand',
                    reportName: 'GrievanceLog',
                    referenceNumber: 'NE_MC_M_0021',
                    frequency: 'Monthly',
                    startDate: '2024-06-01',
                    endDate: '2024-06-30',
                    dueDate: '2024-07-15',
                }));
                setDefaultsSet(true);
            } else {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    reportType: '',
                    startDate: ''
                }));
                setDefaultsSet(false);
            }
        }
    }, [selectedMCOPlanDropdown, defaultsSet]);

    useEffect(() => {
        if (fileValue.length > 0) {
            setIsFileUploaded(true);
        }
    }, [fileValue]);

    function handleInputData(event: any) {
        const { name, value } = event.target
        if (name === 'mcoName') {
            setSelectedMCOPlanDropdown(value)
            setDefaultsSet(false);
        }

        if (name === 'reportName') {
            if (value === 'GrievanceLog') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    referenceNumber: 'NE_MC_M_0021',
                    frequency: 'Monthly',
                    startDate: '2024-06-01',
                    endDate: '2024-06-30',
                    dueDate: '2024-07-15'
                }));
            }
            else if (value === 'TipsReport') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    referenceNumber: 'NE_MC_SM_0001',
                    frequency: 'Semi-Monthly',
                    startDate: '2024-07-01',
                    endDate: '2024-07-15',
                    dueDate: '2024-07-16'
                }));
            }
        }
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    function handleDateInputChange(event: any) {
        const { name, value } = event.target
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleMultiDropdownChange = (values: any) => {
        const stringValue = values.map(String).join(', ')
        setUploadNewReport((prevState: any) => ({
            ...prevState, ['reportUploadChecklist']: stringValue,
        }))
        setSelectedValues(values)
    }

    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
        setUploadNewReport((prevState: any) => ({ ...prevState, ['userComments']: appDetails }))
    }

    const handleDescriptionRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
        setUploadNewReport((prevState: any) => ({ ...prevState, ['description']: appDetails }))
    }

    const uploadChecklist = [
        { label: 'Does it meet the layout specifications of the report?', value: 'layoutSpcReport' },
        { label: 'Is the report complete?', value: 'isReportComplete' },
    ];

    const reportTypeDropdown = [
        { label: 'Standard', value: 'Standard' },
        { label: 'On demand', value: 'ondemand' },
        { label: 'Ad hoc', value: 'adhoc' },
    ];

    const channelDropdown = [
        { label: 'Web', value: 'WEB' },
        { label: 'SFTP', value: 'SFTP' }
    ];
    const submissionStatusDropdown = [
        { label: 'Scheduled', value: 'Scheduled' },
    ];
    const reportNameDropdown = [
        { label: 'Grievance Log', value: 'GrievanceLog' },
        { label: 'Tips Report', value: 'TipsReport' },
    ];

    const frequencyDropdown = [
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quaterly', value: 'Quaterly' },
        { label: 'Semi-Monthly', value: 'Semi-Monthly' },
        { label: 'Annually', value: 'Annually' },
    ];
    const mcoPlanDropdown = [
        { label: 'United Health', value: 'unitedHealth' },
        { label: 'Nebraska Total Plan', value: 'nebraskaTotalPlan' },
        { label: 'Molina', value: 'Molina' },
    ];

    const submissionTypeDropdown = [
        { label: 'Orginal Submission', value: 'orginalSubmission' },
        { label: 'Resubmission', value: 'reSubmission' },
    ];

    const periodDropdown = [
        { label: '06/01/2024-06/30/2024', value: '06/01/2024-06/30/2024' },
        { label: '07/16/24-07/31/24', value: '07/16/24-07/31/24' },
        { label: '07/01/24-07/15/24', value: '07/01/24-07/15/24' }
    ];

    function handleClear() {
        history.goBack()
    }

    function handleSave() {
        setLoadingButton(true)
        const filteredFiles = fileValue.filter(file => file.name);
        uploadNewReport.period = `${moment(uploadNewReport.startDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.endDate).format('MM/DD/YYYY')}`
        uploadNewReport.uploadFiles = filteredFiles;
        uploadNewReport.submissonDate = moment(uploadNewReport.submissionDate).format('MM/DD/YYYY hh:mm A');

        const existingData = localStorage.getItem('uploadNewReportData');
        let dataArray = [];
        if (existingData) {
            dataArray = JSON.parse(existingData);
        }
        dataArray.push(uploadNewReport);

        const uploadNewDataToSave = JSON.stringify(dataArray);
        localStorage.setItem('uploadNewReportData', uploadNewDataToSave);
        alert('New Report data has been successfully uploaded');
        setLoadingButton(false)
        history.push('/admin-dashboard');
    }

    const handleRemoveFile = (fileId: string) => {
        setFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const saveSubmissionDate = () => {
        const formattedDate = moment(uploadNewReport.submissionDate).format('MM/DD/YYYY hh:mm A');
    };

    const openRemoveFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setRemovingFileName(fileValue.find(f => f.id === fileId)?.name || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const confirmDelete = () => {
        if (fileIdToDelete !== null) {
            handleRemoveFile(fileIdToDelete);
        }
        closeRemoveFileModal();
    };

    const handleFileUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                name: file.name,
                type: '',
                uploaded: true
            }));
            setFileValue(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const handleFileTypeChange = (e: any, fileId: any) => {
        const fileType = e.target.value;
        setFileValue((prevFiles) =>
            prevFiles.map((f) =>
                f.id === fileId ? { ...f, type: fileType } : f
            )
        );
    };

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Upload- Submit Reports" />
                </div>
            </div>
            <div className="form-layout">

                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={mcoPlanDropdown}
                                emptyChoiceLabel="Select MCO Plan"
                                name="mcoName"
                                label="MCO Name"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.mcoName || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            // disabled={userType ? true : false}
                            />
                        </div>
                        <div className="element">
                            <Dropdown
                                choices={reportTypeDropdown}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="reportType"
                                label="Reporting Type"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.reportType || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={reportNameDropdown}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="reportName"
                                label="Report Name"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.reportName || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            />

                        </div>
                        <div className="element" >
                            <Dropdown
                                choices={periodDropdown}
                                emptyChoiceLabel="Select period"
                                name="period"
                                label="Period"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.period}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            />

                        </div>
                    </div>
                    <div className="row1">
                        <div className="element">
                            <div className="usa-form-group">
                                <Dropdown
                                    choices={frequencyDropdown}
                                    emptyChoiceLabel="Select Filter Criteria 1"
                                    name="frequency"
                                    label="Frequency"
                                    data-testid="payment_type_testid"
                                    value={uploadNewReport.frequency || ''}
                                    onChange={handleInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="element">
                            <InputText
                                context="registration"
                                label="Reference #"
                                name="referenceNumber"
                                placeholder="Reference Name"
                                value={uploadNewReport.referenceNumber || ''}
                                onChange={handleInputData}
                                disabled={true}
                            // small={true}
                            />

                        </div>
                    </div>
                    <div className="row1">
                        <div className="element">
                            <div className="usa-form-group">
                                <label htmlFor="my-editor">
                                    <b>Description:</b>
                                </label>
                                <ReactQuill
                                    className="my-editor greyed-out"
                                    style={{ maxHeight: '100px', overflowY: 'auto' }}
                                    value={uploadNewReport.description}
                                    onChange={handleDescriptionRichTextChangeAppDetails}
                                    readOnly={true}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                        <div className="element">
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="dueDate"
                                    value={uploadNewReport.dueDate}
                                    onChange={handleDateInputChange}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        {/* <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting Start Date"
                            type="date"
                            name="startDate"
                            value={uploadNewReport.startDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                        />
                    </div>
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting End Date"
                            type="date"
                            name="endDate"
                            value={uploadNewReport.endDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                        />
                    </div>
                </div> */}
                    </div>
                    <div className="row1">
                        <div className="element">
                            <Dropdown
                                choices={channelDropdown}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="channel"
                                label="Submission Channel"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.channel}
                                onChange={handleInputData}
                                disabled={true}
                            />
                        </div>

                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission #"
                                name="submissionNumber"
                                placeholder="Submission Number"
                                value={uploadNewReport.submissionNumber || ''}
                                onChange={handleInputData}
                                disabled={true}
                            // small={true}
                            />
                        </div>
                    </div>

                    <div className="row1">
                        <div className="element">
                            <Dropdown
                                choices={submissionStatusDropdown}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="submissionStatus"
                                label="Submission Status"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.submissionStatus}
                                onChange={handleInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="usa-form-group">
                        <FormInput
                            label="Date of Submission"
                            type="date"
                            name="submissionDate"
                            value={uploadNewReport.submissionDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div> */}
                {/* <div className="row1"> */}
                <div className="details-block">
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Add Notes for the State, if any:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={uploadNewReport.userComments}
                            onChange={handleRichTextChangeAppDetails}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />
                    </div>

                    {/* <div className="row1"> */}
                    {/* <div className="element"> */}
                    {/* <label>
                        <b>Upload a file: <span className="usa-mandatory-label">*</span></b>
                    </label>
                    <div>
                        <label htmlFor="file-upload" style={{
                            display: 'inline-block',
                            padding: '25px',
                            border: '2px solid #3e3e3f',
                            cursor: 'pointer',
                            textAlign: 'center',
                            width: '100%',
                            maxWidth: '30rem',
                            height: '2.5rem',
                            marginTop: '0.5rem',
                        }}>
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                style={{ marginTop: '-20px', position: 'relative' }}
                            />
                        </label>
                    </div>
                </div> */}

                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                            <Button
                                className="OkButton"
                                onClick={handleFileUploadButtonClick}
                            >
                                Upload File
                            </Button>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Attachements:</b>
                        </label>

                        <div className="element">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>No</th>
                                            <th style={{ border: '1px solid black' }}>File Name</th>
                                            <th style={{ border: '1px solid black' }}>File Type</th>
                                            <th style={{ border: '1px solid black' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {fileValue.length > 0 ? (
                                            fileValue.map((file, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                                    <td style={{ border: '1px solid black' }}>{file.name}</td>
                                                    <td style={{ border: '1px solid black' }}>
                                                        {file.uploaded ? (
                                                            <select
                                                                value={file.type}
                                                                onChange={(e) => handleFileTypeChange(e, file.id)}
                                                            >
                                                                <option value="">Select File Type</option>
                                                                <option value="Main Report">Main Report</option>
                                                                <option value="Associated File">Associated File</option>
                                                                <option value="Additional Info">Additional Info</option>
                                                                <option value="Certification File">Certification File</option>
                                                            </select>
                                                        ) : (
                                                            file.type
                                                        )}
                                                    </td>
                                                    <td style={{ border: '1px solid black' }}>
                                                        {file.name && (
                                                            <button
                                                                type="button"
                                                                onClick={() => openRemoveFileModal(file.id.toString())}
                                                                style={{ border: '1px solid red', borderRadius: '5px', padding: '5px' }}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center' }}>No files available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={isRemoveFileModalOpen}
                        onRequestClose={closeRemoveFileModal}
                        contentLabel="Confirm Remove"
                        style={{
                            content: {
                                width: '500px',
                                height: '200px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                            },
                        }}
                    >
                        <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                        <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                        <p style={{ marginTop: '10px' }}>{removingFileName}</p>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={confirmDelete}
                            >
                                Yes
                            </button>
                            <button
                                className='usa-button'
                                onClick={closeRemoveFileModal}
                            >
                                No
                            </button>
                        </div>
                    </Modal>

                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Checklist:</b>
                        </label>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#d3d3d3' }}>
                                        <th style={{ border: '1px solid black' }}>Select</th>
                                        <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                    </tr>
                                </thead>
                                <tbody style={{ alignContent: 'center' }}>
                                    <tr>
                                        <td style={{ border: '1px solid black' }}>
                                            <input type="checkbox" style={{ margin: '5px', transform: 'scale(1.5)' }} />
                                        </td>
                                        <td style={{ border: '1px solid black' }}>Is the report meeting the layout specifications of the report as per latest template?</td>
                                    </tr>
                                    {/* <tr>
                                <td style={{ border: '1px solid black' }}>
                                    <input type="checkbox" style={{ margin: '5px', transform: 'scale(1.5)' }} />
                                </td>
                                <td style={{ border: '1px solid black' }}>Is the report meeting the layout specifications of the report as per latest template?</td>
                            </tr> */}
                                </tbody>
                            </table>
                        </div>
                        {/* </div> */}
                        {/* <div className="element">
                    <div className="usa-form-group">
                        <div >
                            <MultiSelectDropdown
                                options={uploadChecklist}
                                label="Report Upload Checklist"
                                name="reportUploadChecklist"
                                onChange={handleMultiDropdownChange}
                                selectedValues={selectedValues}
                                mandatoryLabel={true}
                            />
                        </div>
                    </div>
                </div> */}
                        {/* <div className="element">
                    <div className="usa-form-group">
                        <label htmlFor="my-editor">
                            <b>User Comments:</b>
                        </label>
                        <ReactQuill
                            className="my-editor"
                            style={{ maxHeight: '100px', overflowY: 'auto' }}
                            value={uploadNewReport.userComments}
                            onChange={handleRichTextChangeAppDetails}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />
                    </div>
                </div> */}
                    </div >
                </div >

                <div className="flex flex-col md:flex-row md:justify-end w-full" style={{ marginBottom: '-20px' }}>
                    <Button
                        disabled={!isFileUploaded || !isFormValid}
                        className="OkButton mb-4 md:mb-0 md:ml-4"
                        onClick={handleSave}
                        loading={loadingButton}
                    >
                        Submit
                    </Button>
                    <Button
                        className="OkButton md:ml-4"
                        onClick={() => handleClear()}
                    >
                        Cancel
                    </Button>
                </div>
            </div >
            <Footer />
        </>
    );

}
export default UploadReportInventory;