import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _ from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import moment from 'moment'
import Modal from 'react-modal';
import * as XLSX from 'xlsx'
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';

export default function ContractReportInventoryDetails() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ContractReport {
        referenceNumber: string;
        mcoName: string;
        reportName?: string;
        frequency: string;
        reportType: string;
        startDate: string;
        endDate: string;
        channel: string;
        dueDate: string;
        submissinStatus: string;
        reviewStatus: string;
        currentSubmission: string;
        currentSubmissionStatus: string;
        currentgroup: string;
        submissionDate: string;
        period: string;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
        file: string;
        uploadFiles: string;
        description: string;
    };

    interface LocationState {
        recordData?: ContractReport;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;

    function handleCancel() {
        history.goBack()
    }

    const handleReviewButton = (data: any) => {
        history.push({
            pathname: '/mco-review',
            state: { recordData: data }
        });
    }

    const handleMcoActionsButton = (data: any) => {
        history.push({
            pathname: '/mco-actions',
            state: { recordData: data }
        });
    }


    interface ContractReviewHistory {
        dateAndTime: string;
        activity: string;
        submission: string;
        updatedBy: string;
    };

    const gridStyle = { minHeight: 340, width: '100%' };
    const contractReportsHistoryHeaders: any[] = [
        {
            name: 'dateAndTime',
            header: (
                <div className='grid-header-custom-style'>
                    Date and Time
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
        },
        {
            name: 'activity',
            header: (
                <div className='grid-header-custom-style'>
                    Activity
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
        },
        {
            name: 'submission',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission #
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'updatedBy',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated By
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Action
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: any) => {
                const handleColumnClick = () => {
                    let pathname = '/mco-actions';
                    const activity = data.activity;

                    switch (activity) {
                        case 'Scheduled for Submission':
                            pathname = '/mco-actions';
                            break;
                        case 'Past due date':
                            pathname = '/mco-actions';
                            break;
                        case 'Request for extension submitted':
                            pathname = '/mco-actions';
                            break;
                        case 'Report Files submitted':
                            pathname = '/mco-actions';
                            break;
                        case 'Rescheduled for resubmission':
                            pathname = '/mco-actions';
                            break;
                        case 'Report Files resubmitted':
                            pathname = '/mco-actions';
                            break;
                        case 'Request for extension rejected':
                            pathname = '/mco-review';
                            break;
                        case 'Reviewed and assigned to other review group':
                            pathname = '/mco-review';
                            break;
                        case 'Reviewed and rejected':
                            pathname = '/mco-review';
                            break;
                        case 'Report files validated for data quality':
                            pathname = '/mco-review';
                            break;
                        case 'Reviewed and accepted':
                            pathname = '/mco-review';
                            break;
                        default:
                            pathname = pathname;
                            break;
                    }

                    history.push({
                        // pathname: '/review-history-details',

                        pathname: pathname,
                        state: {
                            recordData,
                            reviewHistoryData: data,
                            detailsPageReferenceFalg: true,
                        }
                    });
                };

                return (
                    <Button className='okButton' onClick={handleColumnClick}>View</Button>
                );
            },
        }
    ]

    const reviewHistoryinitialData: ContractReviewHistory[] = [
        {
            dateAndTime: '7/24/2024  5:04:16 PM',
            activity: 'Reviewed and accepted',
            submission: '2',
            updatedBy: 'nfarm@nebraksa.gov',
        },
        {
            dateAndTime: '7/24/2024  10:11:16 PM',
            activity: 'Report files validated for data quality',
            submission: '2',
            updatedBy: 'System@nebraksa.gov',
        },
        {
            dateAndTime: '7/24/2024  10:11:16 PM',
            activity: 'Report Files resubmitted',
            submission: '2',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/24/2024  10:11:00 AM',
            activity: 'Rescheduled for resubmission',
            submission: '2',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '7/20/2024  4:53:22 PM',
            activity: 'Reviewed and rejected',
            submission: '1',
            updatedBy: 'nfarm@nebraksa.gov',
        },
        {
            dateAndTime: '7/20/2024  9:23:50 AM',
            activity: 'Reviewed and assigned to other review group',
            submission: '1',
            updatedBy: 'acaprio@nebraksa.gov',
        },
        {
            dateAndTime: '7/20/2024  8:00:00 AM',
            activity: 'Report files validated for data quality',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '7/20/2024  8:00:00 AM',
            activity: 'Report Files submitted',
            submission: '1',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/17/2024  9:20:00 AM',
            activity: 'Request for extension rejected',
            submission: '1',
            updatedBy: 'acaprio@nebraksa.gov',
        },
        {
            dateAndTime: '7/17/2024  9:00:00 AM',
            activity: 'Request for extension submitted',
            submission: '1',
            updatedBy: 'Ajohn@ntp.org',
        },
        {
            dateAndTime: '7/15/2024  9:00:00 AM',
            activity: 'Past due date',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },
        {
            dateAndTime: '6/30/2024  12:00:00 AM',
            activity: 'Scheduled for Submission',
            submission: '1',
            updatedBy: 'System@nebraska.gov',
        },

    ];

    const submissionStatusDropdown = [
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'Resubmitted', value: 'Resubmitted' },
        { label: 'Past Due', value: 'Resubmission' },
        { label: 'Closed', value: 'Closed' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Reopened', value: 'Reopened' },
    ];
    const submissionReviewDropdown = [
        { label: 'Accepted', value: 'Accepted' },
        { label: 'In Review', value: 'In Review' },
        { label: 'Reassigned', value: 'Reassigned' },
        { label: 'Rejected', value: 'Rejected' },

    ];
    let [reviewHistoryListDate, setReviewHistoryListDate] = useState<ContractReviewHistory[]>(recordData?.mcoName === 'Nebraksa Total Care' && recordData?.reviewStatus === 'Accepted' ? reviewHistoryinitialData : []);


    const handleRichTextChangeNotes = (event: any, name: any) => {
        // setBusinessOutcome((prevState: any) => ({ ...prevState, [name]: event }))
    }
    function joinClasses(...classes: (string | undefined)[]): string {
        return classes.filter(Boolean).join(' ')
    }
    const wrapperClasses = joinClasses('usa-fieldset usa-form-group')

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const formatDateForInput = (dateString: string) => {
        let parts;
        if (dateString.includes('/')) {
            parts = dateString.split('/');
            return `${parts[2]}-${parts[0]}-${parts[1]}`;
        } else if (dateString.includes('-')) {
            parts = dateString.split('-');
            return `${parts[0]}-${parts[1]}-${parts[2]}`;
        }
        return '';
    };

    const viewUploadedDocs = (event: any) => {
        const documentUrl = '/assets/docs/ScreenMockUp-ManagedCareCollabTool.pdf';
        window.open(documentUrl, '_blank');
    }

    const formattedStartDate = recordData?.startDate ? formatDateForInput(recordData.startDate) : '';
    const formattedEndDate = recordData?.endDate ? formatDateForInput(recordData.endDate) : '';
    const formattedDueDate = recordData?.dueDate ? formatDateForInput(recordData.dueDate) : '';
    const formattedSubmissionDate = recordData?.submissionDate
        ? moment(recordData.submissionDate).format('MM/DD/YYYY hh:mm A')
        : '';
    const [fileValue, setFileValue] = useState<any[]>([]);

    useEffect(() => {
        if (recordData) {
            const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
            setFileValue(uploadedFiles);
        }
    }, [recordData]);

    const handleRowClick = (data: any) => {
        let pathname = '/mco-actions';
        const activity = data.activity;

        switch (activity) {
            case 'Scheduled for Submission':
                pathname = '/mco-actions';
                break;
            case 'Past due date':
                pathname = '/mco-actions';
                break;
            case 'Request for extension submitted':
                pathname = '/mco-actions';
                break;
            case 'Report Files submitted':
                pathname = '/mco-actions';
                break;
            case 'Rescheduled for resubmission':
                pathname = '/mco-actions';
                break;
            case 'Report Files resubmitted':
                pathname = '/mco-actions';
                break;
            case 'Request for extension rejected':
                pathname = '/mco-review';
                break;
            case 'Reviewed and assigned to other review group':
                pathname = '/mco-review';
                break;
            case 'Reviewed and rejected':
                pathname = '/mco-review';
                break;
            case 'Report files validated for data quality':
                pathname = '/mco-review';
                break;
            case 'Reviewed and accepted':
                pathname = '/mco-review';
                break;
            default:
                pathname = pathname;
                break;
        }

        history.push({
            pathname: pathname,
            state: {
                recordData,
                reviewHistoryData: data,
                detailsPageReferenceFalg: true,
            }
        });
    }
    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
    }

    const headers = [
        'Date & Time',
        'Activity',
        'Submission#',
        'Updated By',
        'MCOs Action',
        'Reviewer Decision',
        'Previous Due Date',
        'New Due Date',
        'MCO-State Note',
        'Internal Note'
    ];

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = reviewHistoryListDate.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'Date & Time': item.dateAndTime,
                    'Activity': item.activity,
                    'Submission#': item.submission,
                    'Updated By': item.updatedBy,
                    'MCOs Action': '',
                    'Reviewer Decision': '',
                    'Previous Due Date': '',
                    'New Due Date': '',
                    'MCO-State Note': '',
                    'Internal Note': ''
                }[header]
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'state actions timeline list');
        XLSX.writeFile(wb, 'state_actions_timeline.xlsx');
        setIsExportModalOpen(false);
    };

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Details" />
                </div>
            </div>

            <div className="form-layout">
                <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>

                    <Button
                        className="OkButton"
                        disabled={recordData?.reviewStatus !== 'Accepted' &&
                            recordData?.reviewStatus !== "" && recordData?.reviewStatus !== 'Rejected'
                        }
                        onClick={() => handleMcoActionsButton(recordData)}
                    >
                        MCO Action
                    </Button>

                    <Button
                        className="OkButton"
                        onClick={() => handleReviewButton(recordData)}
                        disabled={userType === 'HealthPlanUser' || recordData?.reviewStatus === 'Accepted' ||
                            recordData?.reviewStatus === "" || recordData?.reviewStatus === 'Rejected'
                        }
                    >
                        Review
                    </Button>

                    <Button
                        className="OkButton"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="details-block">
                    <h3><b>Report Information:</b></h3>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="MCO Name"
                                name="mcoName"
                                placeholder="Health Plan"
                                value={recordData?.mcoName}
                                onChange={handleGeneralInputData}
                                disabled={true}

                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="reportName"
                                placeholder="Report Name"
                                value={recordData?.reportName}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Reference #"
                                name="referenceNumber"
                                placeholder="Reference Number"
                                value={recordData?.referenceNumber}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Reporting Type"
                                name="reportType"
                                placeholder="Reporting Type"
                                value={recordData?.reportType}
                                // value={'Standard'}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >

                            <InputText
                                context="registration"
                                label=" Submission Channel"
                                name="channel"
                                placeholder="Channel"
                                value={recordData?.channel}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Frequency"
                                name="frequency"
                                placeholder="Frequency"
                                value={recordData?.frequency}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Period"
                                name="mcoName"
                                placeholder="Period"
                                value={recordData?.period}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="dueDate"
                                    value={formattedDueDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    context="registration"
                                    label="Submission Status"
                                    name="submissinStatus"
                                    placeholder="Submission Status"
                                    value={recordData?.submissinStatus}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="element" >

                            <InputText
                                label="Last Submission Date"
                                name="submissionDate"
                                placeholder="Last Submission Date"
                                value={formattedSubmissionDate}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Review Status"
                                    name="currentReviewStatus"
                                    placeholder="Review Status"
                                    value={recordData?.reviewStatus}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />

                                {/* <FormInput
                                    label="Last Submission Date"
                                    type="date"
                                    name="submissionDate"
                                    value={formattedsubmissionDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    required={true}
                                    disabled={true}
                                /> */}

                            </div>
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Review Group"
                                name="currentgroup"
                                placeholder="Review Group"
                                // value={recordData?.currentgroup}
                                value={'MC Control Group'}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                            {/* <Dropdown
                                choices={submissionReviewDropdown}
                                emptyChoiceLabel="Select submission status"
                                name="currentReviewStatus"
                                label="Review Status"
                                data-testid="payment_type_testid"
                                value={recordData?.reviewStatus}
                                onChange={handleGeneralInputData}
                                mandatoryLabel={true}
                                disabled={true}
                            /> */}
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    context="registration"
                                    label="Submission #"
                                    name="currentSubmission"
                                    placeholder="current Submission"
                                    value={recordData?.currentSubmission}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="element">
                            <div className="usa-form-group">
                                <label htmlFor="my-editor">
                                    <b>Description:</b>
                                </label>
                                <ReactQuill
                                    className="my-editor greyed-out"
                                    style={{ maxHeight: '100px', overflowY: 'auto' }}
                                    value={recordData?.description}
                                    onChange={handleRichTextChangeAppDetails}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                            [{ align: [] }], // alignment options
                                            ['clean'], // remove formatting option
                                        ],
                                    }}
                                    readOnly={true}
                                />
                            </div>
                        </div> */}
                    <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                        <label >
                            <b>Description:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={recordData?.description}
                            onChange={handleRichTextChangeAppDetails}
                            className="greyed-out"
                            readOnly={true}
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />
                    </div>
                    {/* <div className="element" >
                            <InputText
                                label="Last Updated By"
                                name="lastUpdatedBy"
                                value={recordData?.lastUpdatedBy}
                                onChange={handleGeneralInputData}
                                mandatoryLabel={true}
                                disabled={true}
                            />
                        </div> */}
                    {/* </div> */}
                    {/* <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Last Updated Date"
                                name="lastUpdatedDate"
                                value={recordData?.lastUpdatedDate}
                                onChange={handleGeneralInputData}
                                mandatoryLabel={true}
                                disabled={true}
                            />
                        </div>
                    </div> */}

                </div>
                <div className="details-block">
                    <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>

                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!reviewHistoryListDate || reviewHistoryListDate.length === 0}
                        >
                            Export to Excel
                        </Button>
                    </div>
                    <h3><b>MCO - State Actions Timeline</b></h3>

                    <ReactDataGrid
                        licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                        idProperty="key"
                        style={gridStyle}
                        pagination
                        columns={contractReportsHistoryHeaders}
                        dataSource={reviewHistoryListDate}
                        sortable={true}
                        // onFilterValueChange={handleFilterChangeGrid}
                        defaultLimit={5}
                        // defaultFilterValue={filterValue}
                        rowHeight={50}
                        onRowClick={({ data }) => handleRowClick(data)}
                        className="pointer-cursor-row"
                        enableColumnAutosize={true}

                    />

                    <Modal
                        isOpen={isExportModalOpen}
                        onRequestClose={toggleModal}
                        contentLabel="Export data"
                        style={{
                            content: {
                                width: '500px',
                                height: '500px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            },
                        }}
                    >
                        <h2 style={{ marginBottom: '20px' }}><b>Select Headers for Export</b></h2>
                        <div>
                            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    id="selectAll"
                                    checked={selectedHeaders.length === (headers.length)}
                                    onChange={handleSelectAllChange}
                                    style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                />
                                <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                            </div>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                {(headers).map((header) => (
                                    <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={header}
                                            checked={selectedHeaders.includes(header)}
                                            onChange={() => handleHeaderChange(header)}
                                            style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                        />
                                        <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                disabled={selectedHeaders.length === 0}
                                className='usa-button'
                                onClick={handleExportToExcel}
                            >
                                Download Excel
                            </button>
                            <button
                                className='usa-button'
                                onClick={toggleModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal>

                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label >
                            <b>Attachments Uploaded/Submitted:</b>
                        </label>
                        <div className="element" style={{ marginTop: 10 }}>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>

                                            <th style={{ border: '1px solid black' }}>Submission Date</th>
                                            <th style={{ border: '1px solid black' }}>Submission #</th>
                                            <th style={{ border: '1px solid black' }}>File Name</th>
                                            <th style={{ border: '1px solid black' }}>File Type</th>
                                            <th style={{ border: '1px solid black' }}>MCO Viewable</th>
                                            <th style={{ border: '1px solid black' }}>Download/View</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {fileValue.length > 0 ? (
                                            fileValue.map((file, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.submissionDate}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.submission}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.name}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.type}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.mcoViewable}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                        <a href={file?.name} target="_blank" rel="noopener noreferrer" className="file-link" style={{ display: 'inline-flex', margin: '5px' }}>
                                                            View File <FaExternalLinkAlt style={{ marginLeft: '5px' }} />
                                                        </a>
                                                        <span style={{ margin: '5px' }}>or</span>
                                                        <a href={file?.name} download className="file-link" style={{ display: 'inline-flex', margin: '5px' }}>
                                                            Download <FaDownload style={{ marginLeft: '5px' }} />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6} style={{ border: '1px solid black', textAlign: 'center' }}>No files available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>MCO-State Notes:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={recordData?.mcoName === 'Nebraksa Total Care' && recordData?.reviewStatus === 'Accepted' ? `<p>7/24/2024  5:04:16 PM nfarm@nebraksa.gov - File found in correct format</p>
            <p>7/24/2024  10:11:00 AM Ajohn@ntp.org - File formatted as per new format and submitted</p>
            <p>7/20/2024  4:53:22 PM nfarm@nebraksa.gov - Can not accept file in old format</p>
            <p>7/20/2024  9:23:50 AM Alex Caprio - File though in old format but seems to have all the data</p>
            <p>7/20/2024  8:00:00 AM Adam John - File is submitted as per old format. Please correct the format and update dates.</p>
            <p>7/17/2024  9:00:00 AM Adam John - Need time for submission due to system issues</p>`: ''}
                            onChange={(ev) =>
                                handleRichTextChangeNotes(ev, 'description')
                            }
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'], // formatting options
                                    [{ list: 'ordered' }, { list: 'bullet' }], // list options
                                    [{ align: [] }], // alignment options
                                    ['clean'], // remove formatting option
                                ],
                            }}
                        />

                    </div>
                </div>

                <div className="row1">
                    <div className="element" >
                        <p><b>Last Updated By : </b>{recordData?.lastUpdatedBy}</p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{recordData?.lastUpdatedDate}</p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}