import React, { ChangeEvent, FocusEvent } from 'react';
import classnames from 'classnames';

interface FormInputProps {
  label: string;
  type: string;
  placeholder?: string;
  id?: string;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string | undefined;
  name?: string;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  width?: number;
  disabled?: boolean; // New prop for disabling the input
  required?: boolean;
  readOnly?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  placeholder,
  id,
  value,
  onChange,
  error,
  name,
  onFocus,
  onBlur,
  width = 45,
  disabled = false, // Default value for disabled
  required = false,
  readOnly = false,
}) => {
  const fieldClasses = classnames('usa-input', {
    'usa-input--error': !!error,
    [`usa-input--${width > 45 ? 'medium' : 'small'}`]: true, // Adjust width class based on the width prop
  });

  const formGroupClasses = classnames('usa-form-group', {
    'usa-form-group--error': !!error,
  });

  return (
    <div className={`flex flex-col md:w-[${width}%] w-full`}>
      <label className="font-bold mb-1 w-full" htmlFor={id} style={{ marginBottom: '-1.5rem' }}>
      {label}{required && <span className="usa-mandatory-label">*</span>}
      </label>
      <div className={formGroupClasses}>
        <div className="usa-input-group">
          <input
            className={`border focus:outline-none px-2 py-2 ${fieldClasses}`}
            placeholder={placeholder}
            id={id}
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
          />
        </div>
        {required && <span>{required}</span>}
        {error && <span className="text-sm text-red-500">{error}</span>}
      </div>
    </div>
  );
};

export default FormInput;
