import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { concat } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import moment from 'moment'
import AdminDashboard from '../adminDashboard/adminDashboard';
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { name } from '@azure/msal-browser/dist/packageMetadata';

export default function ContractReportInventory() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    const CustomCellRenderer = ({ value }: { value: string }) => (
        <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{value}</div>
    );


    function CustomCellColorRenderer(value: any) {
        let textColor = 'black';
        switch (value) {
            case 'Scheduled':
                textColor = 'orange';
                break;
            case 'Reassigned':
                textColor = 'orange';
                break;
            case 'In Review':
                textColor = 'orange';
                break;
            case 'Accepted':
                textColor = 'green';
                break;
            case 'Rejected':
                textColor = 'red';
                break;
            default:
                textColor = 'black';
        }

        return <div style={{ color: textColor }}>
            {value}
        </div>
    }

    const contractReportsHeaders: any[] = [

        {
            name: 'mcoName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'reportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'referenceNumber',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Ref No.
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'frequency',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'period',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'dueDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Due Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            maxWidth: 200,
            render: ({ value }: any) => {
                if (value) {
                    const date = moment(value);
                    return date.isValid() ? date.format('MM/DD/YYYY') : '';
                }
                return '';
            },
            cellRenderer: CustomCellRenderer
        },
        // {
        //     name: 'currentSubmission',
        //     header: 'Current Submission',
        //     defaultFlex: 1,
        //     resizable: true,
        //     minWidth: 100,
        //     maxWidth: 200,
        //     cellRenderer: CustomCellRenderer
        // },

        {
            name: 'submissinStatus',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            cellRenderer: CustomCellRenderer
        },
        {
            name: 'submissionDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 90,
            maxWidth: 150,
            render: ({ value }: any) => {
                if (value) {
                    const date = moment(value);
                    return date.isValid() ? date.format('M/D/YY h:mm A') : '';
                }
                return '';
            },
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'reviewStatus',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Review Status
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ value }: any) => CustomCellColorRenderer(value),
            // render: ({ data }: any) => {
            //     // Assuming `data` contains an object with a `reviewStatus` property
            //     const reviewStatus = data.reviewStatus;

            //     const handleRowClick = () => {
            //         history.push({
            //             pathname: '/contract-report-details',
            //             state: { recordData: data }
            //         });
            //     };

            //     return (
            //         <div>
            //             <Button className='okButton' onClick={handleRowClick}>
            //                 {reviewStatus ? reviewStatus : 'Details'}
            //             </Button>
            //         </div>
            //     );
            // },
        },
        {
            name: 'lastUpdatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            maxWidth: 200,
            render: ({ value }: any) => {
                if (value) {
                    const date = moment(value);
                    return date.isValid() ? date.format('M/D/YY h:mm A') : '';
                }
                return '';
            },
            cellRenderer: CustomCellRenderer
        }
    ]

    type UploadFile = {
        id: string;
        submissionDate: string;
        submission: string;
        name: string;
        type: string;
        mcoViewable?: string;
    };

    interface ContractReport {
        referenceNumber: string;
        mcoName: string;
        reportName: string;
        frequency: string;
        reportType?: string;
        startDate: string;
        endDate: string;
        channel: string;
        submissionDate: string;
        period: string;
        dueDate: string;
        currentSubmission: string;
        submissinStatus: string;
        reviewStatus: string;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
        file?: string;
        uploadFiles?: UploadFile[];
        reviewNumber?: string;
    };

    const initialData: ContractReport[] = [
        {
            referenceNumber: 'NE_MC_SM_0001',
            mcoName: 'Molina',
            reportName: 'Tips Report',
            frequency: 'Semi-Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '07/16/24-07/31/24',
            dueDate: '08/01/2024',
            currentSubmission: '1',
            submissionDate: '',
            submissinStatus: 'Scheduled',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '8/1/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_SM_0001',
            mcoName: 'Nebraska Total Care',
            reportName: 'Tips Report',
            frequency: 'Semi-Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '07/16/24-07/31/24',
            dueDate: '08/01/2024',
            currentSubmission: '1',
            submissionDate: '',
            submissinStatus: 'Scheduled',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '8/1/24 12:00 PM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_SM_0001',
            mcoName: 'United Health Care',
            reportName: 'Tips Report',
            frequency: 'Semi-Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '07/16/24-07/31/24',
            dueDate: '08/01/2024',
            currentSubmission: '1',
            submissionDate: '',
            submissinStatus: 'Scheduled',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '8/1/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_M_0021',
            mcoName: 'Molina',
            reportName: 'Grievance Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'WEB',
            period: '06/01/24-06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '1',
            submissionDate: '',
            submissinStatus: 'Past Due',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '7/1/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_M_0021',
            mcoName: 'Nebraska Total Care',
            reportName: 'Grievance Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'WEB',
            period: '06/01/24-06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '1',
            submissionDate: '7/9/24 12:00 AM',
            submissinStatus: 'Closed',
            reviewStatus: 'Accepted',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '7/9/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_M_0021',
            mcoName: 'United Health Care',
            reportName: 'Grievance Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'WEB',
            period: '06/01/24-06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '2',
            submissionDate: '7/18/24 12:00 AM',
            submissinStatus: 'Resubmitted',
            reviewStatus: 'In Review',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '7/18/24 12:00 AM',
            reportType: 'Standard',
            reviewNumber: '1',
            uploadFiles: [
                { id: '1', submissionDate: '7/24/24 10:11 AM', submission: '2', name: 'AppealsLog_NTC_2024_06.XLS', type: 'Main Report', mcoViewable: 'No' }
            ]
        },
        {
            referenceNumber: 'NE-MC-R-0038',
            mcoName: 'Molina',
            reportName: 'Appeal Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '06/01/24-06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '1',
            submissionDate: '7/9/24 12:00 AM',
            submissinStatus: 'Submitted',
            reviewStatus: 'In Review',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '7/9/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE-MC-R-0038',
            mcoName: 'Nebraksa Total Care',
            reportName: 'Appeal Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '06/01/24 - 06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '2',
            submissionDate: '7/24/24 12:00 AM',
            submissinStatus: 'Closed',
            reviewStatus: 'Accepted',
            lastUpdatedBy: 'nfarm@nebraksa.gov',
            lastUpdatedDate: '7/24/24 12:00 AM',
            file: 'Appeal Log_2024-06-01_2024-06-30.csv',
            reportType: 'Standard',
            uploadFiles: [
                { id: '1', submissionDate: '7/24/24 10:11 AM', submission: '2', name: 'AppealsLog_NTC_2024_06.XLS', type: 'Main Report' },
                { id: '2', submissionDate: '7/24/24 10:11 AM', submission: '2', name: 'AppealsLog_NTC_2024_06_A1.XLS', type: 'Associated File' },
                { id: '3', submissionDate: '7/24/24 10:11 AM', submission: '2', name: 'AppealsLog_NTC_2024_06_ATT.PDF', type: 'Associated File' },
                { id: '4', submissionDate: '7/24/24 10:11 AM', submission: '2', name: 'AppealsLog_NTC_2024_06_ADD.PDF', type: 'Additional Info.' },
                { id: '5', submissionDate: '7/20/24 8:00 AM', submission: '1', name: 'AppealsLog_NTC_2024_06.XLS', type: 'Main Report' },
                { id: '6', submissionDate: '7/20/24 8:00 AM', submission: '1', name: 'AppealsLog_NTC_2024_06_A1.XLS', type: 'Associated File' },
                { id: '7', submissionDate: '7/20/24 8:00 AM', submission: '1', name: 'AppealsLog', type: 'Certification File' },
            ]
        },
        {
            referenceNumber: 'NE-MC-R-0038',
            mcoName: 'United Health Care',
            reportName: 'Appeal Log',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '06/01/24 - 06/30/24',
            dueDate: '07/15/2024',
            currentSubmission: '1',
            submissionDate: '7/15/24 12:00 AM',
            submissinStatus: 'Closed',
            reviewStatus: 'Accepted',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '7/15/24 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_M_0022',
            mcoName: 'Molina',
            reportName: 'EVV KPI-Home Health',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '07/01/24-07/31/24',
            dueDate: '8/15/2024',
            currentSubmission: '1',
            submissionDate: '7/15/24 12:00 AM',
            submissinStatus: 'Scheduled',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '8/1/2024 12:00 AM',
            reportType: 'Standard',
        },
        {
            referenceNumber: 'NE_MC_M_0011',
            mcoName: 'Molina',
            reportName: 'Care Management Report',
            frequency: 'Monthly',
            startDate: '03/15/2024',
            endDate: '09/15/2024',
            channel: 'SFTP',
            period: '07/01/24-07/31/24',
            dueDate: '8/15/2024',
            currentSubmission: '1',
            submissionDate: '7/15/24 12:00 AM',
            submissinStatus: 'Scheduled',
            reviewStatus: '',
            lastUpdatedBy: 'Admin',
            lastUpdatedDate: '8/1/2024 12:00 AM',
            reportType: 'Standard',
        },

    ];

    let [filterData, setFilterData] = useState<ContractReport[]>([]);

    useEffect(() => {
        setIsLoading(true);
        const storedData = localStorage.getItem('uploadNewReportData');
        let combinedData = Array.isArray(initialData) ? [...initialData] : [];
        const parsedData = JSON.parse(storedData || '[]');

        try {
            if (userType) {
                if (Array.isArray(parsedData)) {
                    const combinedData = initialData.concat(parsedData);
                    const filteredData = combinedData.filter(item => item.mcoName.toLowerCase().includes('molina'));
                    setFilterData(filteredData);
                    console.log('parsedData:', filterData);

                }
            } else {
                combinedData = combinedData.concat(parsedData);
                setFilterData(combinedData);
            }
        } catch (error) {
            console.error('Error parsing contract reports from local storage:', error);
        }
        setIsLoading(false);
    }, []);

    const serachDropdownChoices1 = [
        {
            label: 'Review User Group',
            value: 'reviewUserGroup',
        },
        {
            label: 'Review User ID',
            value: 'reviewUserId',
        },
        {
            label: 'Review Date',
            value: 'reviewDate',
        },
        {
            label: 'Submission Number',
            value: 'submissionNumber',
        },
        {
            label: 'Report Type',
            value: 'reportType',
        },
        {
            label: 'Channel',
            value: 'channel',
        },
        {
            label: 'Exempt-able',
            value: 'exemptable',
        },

    ]

    const serachDropdownChoices2 = [
        {
            label: 'Review User Group',
            value: 'reviewUserGroup',
        },
        {
            label: 'Review User ID',
            value: 'reviewUserId',
        },
        {
            label: 'Review Date',
            value: 'reviewDate',
        },
        {
            label: 'Submission Number',
            value: 'submissionNumber',
        },
        {
            label: 'Report Type',
            value: 'reportType',
        },
        {
            label: 'Channel',
            value: 'channel',
        },
        {
            label: 'Exempt-able',
            value: 'exemptable',
        },
    ]

    const filteredSerachDropdownChoices1 = userType === 'HealthPlanUser'
        ? serachDropdownChoices1.filter(option => option.value === 'submissionNumber' ||
            option.value === 'reportType' || option.value === 'channel' || option.value === 'referenceNumber' ||
            option.value === 'exemptable'
        )
        : serachDropdownChoices1;

    const filteredSerachDropdownChoices2 = userType === 'HealthPlanUser'
        ? serachDropdownChoices2.filter(option => option.value === 'submissionNumber' ||
            option.value === 'reportType' || option.value === 'channel' || option.value === 'referenceNumber' ||
            option.value === 'exemptable')
        : serachDropdownChoices2

    const filterValue = [
        { name: 'mcoName', operator: 'contains', type: 'string', value: '' },
        { name: 'reportName', operator: 'contains', type: 'string', value: '' },
        { name: 'referenceNumber', operator: 'contains', type: 'string', value: '' },
        { name: 'frequency', operator: 'contains', type: 'string', value: '' },
        { name: 'period', operator: 'contains', type: 'string', value: '' },
        { name: 'dueDate', operator: 'contains', type: 'string', value: '' },
        { name: 'currentSubmission', operator: 'contains', type: 'string', value: '' },
        { name: 'startDate', operator: 'contains', type: 'string', value: '' },
        { name: 'endDate', operator: 'contains', type: 'string', value: '' },
        { name: 'channel', operator: 'contains', type: 'string', value: '' },
        { name: 'submissionDate', operator: 'contains', type: 'string', value: '' },
        { name: 'submissinStatus', operator: 'contains', type: 'string', value: '' },
        { name: 'reviewStatus', operator: 'contains', type: 'string', value: '' },
        { name: 'lastUpdatedDate', operator: 'contains', type: 'string', value: '' },
    ]
    const [selectedOption1, setSelectedOption1] = useState<any>(null);
    const [selectedOption2, setSelectedOption2] = useState<any>(null);
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        setFilter1Text(null);
        setFilter2Text(null);
        setSelectedOption1(null);
        setSelectedOption2(null);
        // Ensure getAllBusinessAppList is defined and used correctly
        // getAllBusinessAppList(true, currentStatusDropdownState);
    }

    function handleSearch() {
        // savedFilters.businessApplicationMain = {
        //   selectedOption1,
        //   selectedOption2,
        //   filter1Text,
        //   filter2Text,
        // } as any
        // getAllBusinessAppList(false, currentStatusDropdownState)
    }

    const handleAdhocOrNewReport = () => {
        history.push('/adhoc-or-new-report');
    }

    const handleRowClick = (data: any) => {
        history.push({
            pathname: '/contract-report-details',
            state: { recordData: data }
        });
    }

    const [filterDataList, setFilterDataList] = useState<any[]>([]);

    useEffect(() => {
        const filteredData = filterData.filter((item: any) => {
            return filterValue.every(filter => {
                const { name, value } = filter;
                const itemValue = item[name];

                if (name === 'status') {
                    if (value === null || value === '' || value === 'undefined') {
                        return true;
                    } else {
                        return itemValue.toLowerCase() === value.toLowerCase();
                    }
                } else if (itemValue === null || itemValue === undefined || itemValue === '') {
                    return value === '';
                } else if (value === 'Active') {
                    return itemValue.toLowerCase() === value.toLowerCase();
                }
                else {
                    return itemValue.toLowerCase().includes(value.toLowerCase());
                }
            });
        });
        setFilterDataList(filteredData);
    }, [filterData, filterValue]);

    const headers = [
        'MCO Name',
        'Report Name',
        'Ref No.',
        'Frequency',
        'Period',
        'Due Date',
        'Submission Status',
        'Submission Date',
        'Review Status',
        'Updated Date',
        'Review User Group',
        'Review User ID',
        'Review Date',
        'Submission Number',
        'Report Type',
        'Channel',
    ];

    const mcoheaders = [
        'MCO Name',
        'Report Name',
        'Ref No.',
        'Frequency',
        'Period',
        'Due Date',
        'Submission Status',
        'Submission Date',
        'Review Status',
        'Updated Date',
        'Submission Number',
        'Report Type',
        'Channel',
    ]

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    useEffect(() => {
        if (userType === 'HealthPlanUser') {
            setSelectedHeaders(mcoheaders);
        } else {
            setSelectedHeaders(headers);
        }
    }, [userType]);
    const stripHtmlTags = (html: any) => {
        const tmp = document.createElement('div')
        tmp.innerHTML = html
        return tmp.textContent || tmp.innerText || ''
    }
    const toggleModal = () => {
        setSelectedHeaders(userType === 'HealthPlanUser' ? mcoheaders : headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterData.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'MCO Name': item.mcoName,
                    'Report Name': item.reportName,
                    'Ref No.': item.referenceNumber,
                    'Frequency': item.frequency,
                    'Period': item.period,
                    'Due Date': item.dueDate,
                    'Submission Status': item.submissinStatus,
                    'Submission Date': moment(item.submissionDate).format('MM/DD/YYYY'),
                    'Review Status': item.reviewStatus,
                    'Updated Date': moment(item.lastUpdatedDate).format('MM/DD/YYYY'),
                    'Channel': item.channel,
                }[header]
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'report inventory list');
        XLSX.writeFile(wb, 'report_inventory_list.xlsx');
        setIsExportModalOpen(false);
    };


    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Reports Inventory" />
                </div>
            </div>

            <div className="form-layout">
                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!filterData || filterData.length === 0}
                        >
                            Export to Excel
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={handleAdhocOrNewReport}
                        >
                            Add
                        </Button>

                    </div>
                </div>
                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Headers for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === (userType === 'HealthPlanUser' ? mcoheaders.length : headers.length)}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {(userType === 'HealthPlanUser' ? mcoheaders : headers).map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>

                <ReactDataGrid
                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                    idProperty="key"
                    style={gridStyle}
                    pagination
                    columns={contractReportsHeaders}
                    dataSource={filterData}
                    sortable={true}
                    // onFilterValueChange={handleFilterChangeGrid}
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    rowHeight={50}
                    onRowClick={({ data }) => handleRowClick(data)}
                    className="pointer-cursor-row"
                    enableColumnAutosize={true}
                    handle={setGridRef}
                />
            </div>
            <Footer />
        </>
    );
}