import React, { useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reminders-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'

const Reminders = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ReminderList {
        alert: string;
        alertDetails: string;
        reportName: string;
        reportingPeriod: string;
        mco: string;
        date?: string;
    }

    // Example initialData for useState
    const initialData: ReminderList[] = [
        { alert: 'Report past due', alertDetails: "Report is past due for the MCO's submission", reportName: "Grievances Log", reportingPeriod: "06/01/24-06/30/24", mco: "United Health Care", date: '7/16/2024 ' },
        { alert: 'Report past due', alertDetails: "Report is past due for the MCO's submission", reportName: "Appeal Log", reportingPeriod: "06/01/24-06/30/24", mco: "Nebraska Total Care", date: '7/16/2024 ' },

    ];
    const initialDataforMcoUser: ReminderList[] = [
        { alert: 'Report past due', alertDetails: "Report is past due for the MCO's submission", reportName: "Grievances Log", reportingPeriod: "06/01/24-06/30/24", mco: "Molina", date: '7/16/2024 ' },

    ];
    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    let [filterData, setFilterData] = useState<ReminderList[]>(userType ? initialDataforMcoUser : initialData);
    const filterValue = [
        { name: 'alert', operator: 'contains', type: 'string', value: '' },
        { name: 'date', operator: 'contains', type: 'string', value: '' },
        { name: 'alertDetails', operator: 'contains', type: 'string', value: '' },
        { name: 'reportName', operator: 'contains', type: 'string', value: '' },
        { name: 'reportingPeriod', operator: 'contains', type: 'string', value: '' },

    ]

    const remindersListHeaders: any[] = [
        {
            name: 'date',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'alert',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            // maxWidth: 300,
        },
        {
            name: 'alertDetails',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert Details
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'mco',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            name: 'reportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
        },

        {
            name: 'reportingPeriod',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reporting Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            name: 'reviewStatus',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Review Status
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: any) => {
                const handleColumnClick = () => {
                    history.push({
                        // pathname: '/contract-report-details',
                        state: { recordData: data }
                    });
                };

                return (
                    <Button className='OkButton' onClick={handleColumnClick}>Details</Button>
                );
            },
        }
    ]

    const handleRowClick = (data: any) => {
        history.push({
            // pathname: '/contract-report-details',
            state: { recordData: data }
        });
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Reminders and Alerts" />
                </div>
            </div>

            <div className="form-layout">
                <ReactDataGrid
                    licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                    idProperty="key"
                    style={gridStyle}
                    pagination
                    columns={remindersListHeaders}
                    dataSource={filterData}
                    sortable={true}
                    // onFilterValueChange={handleFilterChangeGrid}
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    rowHeight={50}
                    onRowClick={({ data }) => handleRowClick(data)}
                    className="pointer-cursor-row"
                    enableColumnAutosize={true}
                    handle={setGridRef}
                />
            </div>
            <Footer />
        </>
    );
}
export default Reminders;