import classnames from "classnames";
import uniqueId from "lodash/uniqueId";
import React from "react";
import InputMask, { BeforeMaskedStateChangeStates, InputState } from "react-input-mask";
import FormLabel from "../Common/FormLabel";
import Fieldset from "../Common/Fieldset";

function InputText({ type = "text", ...props }: InputTextProps) {
    // Generate a unique ID for associating field elements like the
    // input, label, and error message. This is important for a11y.
    const inputId = uniqueId("InputText");
    const hasError = !!props.errorMsg || props.showErrorWithoutMessage;

    const fieldClasses = classnames("usa-input", props.inputClassName, {
        "usa-input--error": hasError,
        [`usa-input--${props.width}`]: !!props.width,
        "margin-top-3": props.large,
    });
    const formGroupClasses = classnames("usa-form-group", props.formGroupClassName, {
        "usa-form-group--error": hasError,
        "margin-top-5": props.large,
        "margin-top-2": props.small,
    });

    const formGroupInputClasses = classnames("usa-input-group", props.formGroupInputClasses);

    return (
        <Fieldset
            className={formGroupClasses}
            hint={props.legendHint}
            legend={props.legend || null}
            legendStyle={props.legendStyle}
            legendBold={props.legendBold}
        >
            <React.Fragment>
                <FormLabel
                    errorMsg={props.errorMsg || (props.showErrorWithoutMessage && " ")}
                    inputId={inputId}
                    hint={props.hint}
                    large={props.large}
                    bold={props.bold}
                    optionalText={props.optionalText}
                >
                    {props.label}  
                    {props.mandatoryLabel && (
                        <span className="usa-mandatory-label">
                           *
                        </span>
                    )}
                </FormLabel>

                {type === "textarea" ? (
                    <textarea
                        className={fieldClasses}
                        id={inputId}
                        onChange={props.onChange}
                        value={props.value}
                        style={{ height: "initial" }}
                        data-testid={((props.context ? props.context + "." : "") + props.name).replace(
                            /[^\w.]/gi,
                            ""
                        )}
                    ></textarea>
                ) : (
                    <div className={formGroupInputClasses}>
                        {props.inputPrefix && (
                            <div className="usa-input-prefix" aria-hidden="true">
                                {props.inputPrefix}
                            </div>
                        )}
                        <InputMask
                            mask={props.mask || ""}
                            className={fieldClasses}
                            id={inputId}
                            type={type}
                            data-testid={(
                                (props.context ? props.context + "." : "") + props.name
                            ).replace(/[^\w.]/gi, "")}
                            data-item-id={props.itemId}
                            onChange={props.onChange}
                            maskPlaceholder={props.maskPlaceholder}
                            alwaysShowMask={props.alwaysShowMask}
                            inputRef={props.inputRef}
                            beforeMaskedStateChange={props.beforeMaskedStateChange}
                            autoComplete={props.autoComplete}
                            inputMode={props.inputMode}
                            maxLength={props.maxLength}
                            name={props.name}
                            pattern={props.pattern}
                            placeholder={props.placeholder}
                            value={props.value}
                            readOnly={props.readOnly}
                            disabled={props.disabled}
                        />
                    </div>
                )}
            </React.Fragment>
        </Fieldset>
    );
}

interface InputTextProps {
    /* INPUT MASK REALTED PROPS */
    /**
     * Mask string. Format characters are:
     * * `9`: `0-9`
     * * `a`: `A-Z, a-z`
     * * `\*`: `A-Z, a-z, 0-9`
     *
     * Any character can be escaped with backslash, which usually will appear as double backslash in JS strings.
     * For example, German phone mask with unremoveable prefix +49 will look like `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`
     */
    mask?: string | Array<string | RegExp>;
    /**
     * Character to cover unfilled editable parts of mask. Default character is "_". If set to null, unfilled parts will be empty, like in ordinary input.
     */
    maskPlaceholder?: string | null | undefined;
    /**
     * Show mask even in empty input without focus.
     */
    alwaysShowMask?: boolean | undefined;
    /**
     * Use inputRef instead of ref if you need input node to manage focus, selection, etc.
     */
    inputRef?: React.Ref<HTMLInputElement> | undefined;
    /**
     * In case you need to implement more complex masking behavior, you can provide
     * beforeMaskedStateChange function to change masked value and cursor position
     * before it will be applied to the input.
     *
     * * previousState: Input state before change. Only defined on change event.
     * * currentState: Current raw input state. Not defined during component render.
     * * nextState: Input state with applied mask. Contains value and selection fields.
     */
    beforeMaskedStateChange?(states: BeforeMaskedStateChangeStates): InputState;
    /*END INPUT MASK RELATED PROPS */

    /**
     * If you want to show it as an error without the message
     */
    showErrorWithoutMessage?: boolean | undefined;
    /**
     * Enable or disable autocomplete
     */
    autoComplete?: React.InputHTMLAttributes<HTMLInputElement>["autoComplete"];
    /**
     * Localized error message. Setting this enables the error state styling.
     */
    errorMsg?: React.ReactNode;
    /**
     * Additional classes to include on the containing form group element
     */
    formGroupClassName?: string;
 /**
     * Additional classes to include on the containing form group element input
     */
    formGroupInputClasses?: string;
    /**
     * Localized hint text
     */
    hint?: React.ReactNode;
    /**
     * Additional classes to include on the HTML input
     */
    inputClassName?: string;
    /** none, text, url, email, numeric, decimal, search */
    inputMode?: React.InputHTMLAttributes<HTMLInputElement>["inputMode"];
    /** data-item-id to trace which element you are working with */
    itemId?: string;
    /**
     * Localized field label
     */
    label?: React.ReactNode;
    /**
     * Large variant of label
     */
    large?: boolean;
    /** Maximum amout of characters the user can enter */
    maxLength?: React.InputHTMLAttributes<HTMLInputElement>["maxLength"];
    /**
     * HTML input `name` attribute
     */
    name: React.InputHTMLAttributes<HTMLInputElement>["name"];
    /**
     * HTML input `onChange` attribute
     */
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    /**
     * Localized text indicating this field is optional
     */
    optionalText?: React.ReactNode;
    /**
     * The format pattern to allow input 
     */
    pattern?: React.InputHTMLAttributes<HTMLInputElement>["pattern"];
    placeholder?: React.InputHTMLAttributes<HTMLInputElement>["placeholder"];
    /**
     * A more compact version of the input
     */
    small?: boolean;
    /**
     * Type of input (text, textarea)
     */
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
    /**
     * Change the width of the input field
     */
    width?: "small" | "medium";
    /**
     * Sets the input's `value`. Use this in combination with `onChange`
     * for a controlled component.
     */
    value?: React.InputHTMLAttributes<HTMLInputElement>["value"];
    /**
     * The context to differentiate from other text boxes
     */
    context?: string;
    /**
     * Enable the bold variant of label
     */
    bold?: boolean;
    /**
     * Sets the input's pre-fix
     */
    inputPrefix?: React.ReactNode;
    /**
      * Localized label for the entire fieldset
      */
     legend?: React.ReactNode;
     /**
      * Styling for the legend
      */
     legendStyle?: "default" | "large";
     /**
      * Should the legend be bold faced
      */
     legendBold?: boolean;
     /**
     * Localized hint text for the entire fieldset
     */
      legendHint?: React.ReactNode;

      readOnly?: boolean;

      disabled?: boolean;

      mandatoryLabel?: boolean;
}

export default InputText;
