import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import './CheckboxDropdown.css'

// Assuming `options`, `onChange`, `selectedValues`, `label`, and now `mandatoryLabel` are passed as props
const MultiSelectDropdown = ({ options, onChange, selectedValues, label, mandatoryLabel = false }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetWidth, setTargetWidth] = useState(0)
  const targetRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleResize = () => {
    if (targetRef.current) {
      setTargetWidth((targetRef.current as any).clientWidth)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOptionClick = (value: any) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v: any) => v !== value)
      : [...selectedValues, value]
    onChange(updatedValues)
  }

  const getSelectedCount = () => {
    return selectedValues.length > 0 ? `+${selectedValues.length}` : ''
  }

  const dropdownContainerClasses = classnames(
    'multi-select-dropdown',
    'usa-form-group',
    'margin-top-1',
  )
  const selectClasses = classnames('usa-select', {
    'dropdown-arrow': true,
    open: isOpen,
  })

  return (
    <div className={dropdownContainerClasses}>
      {label && (
        <label htmlFor="multiSelectDropdown" style={{ fontWeight: 'bold' }}>
          {label}{mandatoryLabel && <span className="usa-mandatory-label">*</span>}
        </label>
      )}
      <div id="multiSelectDropdown" ref={targetRef} className={selectClasses} onClick={toggleDropdown}>
        Select Options {getSelectedCount()}
      </div>
      {isOpen && (
        <div className="dropdown-options" style={{ width: targetWidth }}>
          {options.map((option: any) => (
            <div key={option.value} className="option"  style={{ padding: '5px 5px 5px 5px' }}>
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleOptionClick(option.value)}
                style={{ width: '15px', height: '15px', marginRight: '5px' }}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MultiSelectDropdown