import React, { useState, useEffect, useRef } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _ from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';

export default function McoActionsPage() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const userType = sessionStorage.getItem('signedInUserType');
    const [removingFileName, setRemovingFileName] = useState('');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ContractReport {
        referenceNumber: string;
        mcoName: string;
        reportName?: string;
        frequency: string;
        reportingType: string;
        startDate: string;
        endDate: string;
        channel: string;
        dueDate: string;
        submissinStatus: string;
        reviewStatus: string;
        currentSubmission: string;
        currentSubmissionStatus: string;
        currentgroup: string;
        submissionDate: string;
        period: string;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
        file: string;
        uploadFiles: string;
    };

    interface LocationState {
        recordData?: ContractReport;
        detailsPageReferenceFalg?: boolean;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;
    const detailsPageReferenceFalg = (location?.state as LocationState)?.detailsPageReferenceFalg || false;

    function handleCancel() {
        history.goBack()
    }

    function handleSaveButton() {
    }

    interface FileWithMetadata {
        id: number;
        name: string;
        type: string;
        uploaded: boolean;
    }

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileValue, setFileValue] = useState<FileWithMetadata[]>([]);

    const handleFileUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                name: file.name,
                type: '',
                uploaded: true
            }));
            setFileValue(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const handleFileTypeChange = (e: any, fileId: any) => {
        const fileType = e.target.value;
        setFileValue((prevFiles) =>
            prevFiles.map((f) =>
                f.id === fileId ? { ...f, type: fileType } : f
            )
        );
    };

    const handleRemoveFile = (fileId: string) => {
        setFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const submissionStatusDropdown = [
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'Resubmitted', value: 'Resubmitted' },
        { label: 'Past Due', value: 'Resubmission' },
        { label: 'Closed', value: 'Closed' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Reopened', value: 'Reopened' },
    ];
    const chooseActionDropdown = [
        { label: 'Extend', value: 'Extend' },
        { label: 'Submit', value: 'Submit' },
        { label: 'Reopen', value: 'Reopen' },
    ];

    const handleRichTextChangeNotes = (event: any, name: any) => {
        // setBusinessOutcome((prevState: any) => ({ ...prevState, [name]: event }))
    }

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const [chooseActions, setChooseActions] = useState('');
    const [chooseExtendDueDate, setChooseExtendDueDate] = useState('');

    const handleChooseActionsDropdownData = (event: any) => {
        const { name, value } = event.target
        setChooseActions(value)
    }

    const handleExtendDueDate = (event: any) => {
        const { name, value } = event.target
        setChooseExtendDueDate(value)
    }
    const formatDateForInput = (dateString: string) => {
        let parts;
        if (dateString.includes('/')) {
            parts = dateString.split('/');
            return `${parts[2]}-${parts[0]}-${parts[1]}`;
        } else if (dateString.includes('-')) {
            parts = dateString.split('-');
            return `${parts[0]}-${parts[1]}-${parts[2]}`;
        }
        return '';
    };

    const viewUploadedDocs = (event: any) => {
        const documentUrl = '/assets/docs/ScreenMockUp-ManagedCareCollabTool.pdf';
        window.open(documentUrl, '_blank');
    }

    const formattedStartDate = recordData?.startDate ? formatDateForInput(recordData.startDate) : '';
    const formattedEndDate = recordData?.endDate ? formatDateForInput(recordData.endDate) : '';
    const formattedSubmissionDate = recordData?.submissionDate ? formatDateForInput(recordData.submissionDate) : '';
    const formattedDueDate = recordData?.dueDate ? formatDateForInput(recordData.dueDate) : '';
    const formattedsubmissionDate = recordData?.submissionDate ? formatDateForInput(recordData.submissionDate) : '';

    useEffect(() => {
        if (recordData) {
            const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
            setFileValue(uploadedFiles);
        }
    }, [recordData]);

    const openRemoveFileModal = (fileId: any) => {
        console.log('fileId--', fileId)
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        console.log('TEST--', fileValue)
        setRemovingFileName(fileValue.find(f => f.id === Number(fileId))?.name || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const confirmDelete = () => {
        if (fileIdToDelete !== null) {
            handleRemoveFile(fileIdToDelete);
        }
        closeRemoveFileModal();
    };

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="MCO's Actions" />
                </div>
            </div>

            <div className="form-layout">
                <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={handleSaveButton}
                    >
                        Save
                    </Button>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={handleSaveButton}
                    >
                        Submit
                    </Button>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div>

                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="MCO Name"
                                name="mcoName"
                                value={recordData?.mcoName}
                                onChange={handleGeneralInputData}
                                disabled={true}

                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="reportName"
                                value={recordData?.reportName}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Frequency"
                                name="frequency"
                                value={recordData?.frequency}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                label="Period"
                                name="mcoName"
                                value={recordData?.period}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row1">
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="dueDate"
                                    value={formattedDueDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission Channel"
                                name="channel"
                                value={recordData?.channel}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Current Submission"
                                name="currentSubmission"
                                placeholder="current Submission"
                                value={recordData?.currentSubmission}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="details-block">
                        <div className="flex items-center space-x-4">
                            <div className="element">
                                <Dropdown
                                    choices={chooseActionDropdown}
                                    emptyChoiceLabel="Select action"
                                    name="submissinStatus"
                                    label="Choose an action"
                                    data-testid="payment_type_testid"
                                    value={chooseActions}
                                    onChange={handleChooseActionsDropdownData}
                                    mandatoryLabel={true}
                                    disabled={detailsPageReferenceFalg ? true : false}
                                />
                            </div>
                            {chooseActions === 'Extend' && (
                                <div className="element usa-form-group">
                                    <FormInput
                                        label="Extend Due Date to"
                                        type="date"
                                        name="extendDueDate"
                                        value={chooseExtendDueDate}
                                        onChange={handleExtendDueDate}
                                        placeholder={'MM-DD-YYYY'}
                                        id={''}
                                        required={true}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                            <label style={{ marginBottom: '10px' }}>
                                <b>Add Notes for the State Reviewer:</b>
                            </label>
                            <ReactQuill
                                style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                                value='Test notes'
                                className={detailsPageReferenceFalg? "greyed-out":''}
                                readOnly={detailsPageReferenceFalg ? true : false}
                                onChange={(ev) =>
                                    handleRichTextChangeNotes(ev, 'description')
                                }
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                        [{ align: [] }],
                                        ['clean'],
                                    ],
                                }}
                            />

                        </div>

                        {!detailsPageReferenceFalg && (
                            <>
                                <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                                        <Button
                                            className="OkButton"
                                            onClick={handleFileUploadButtonClick}
                                        >
                                            Upload File
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    <label style={{ marginBottom: '10px' }}>
                                        <b>Attachments, if any required:</b>
                                    </label>

                                    <div className="element">
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#d3d3d3' }}>
                                                        <th style={{ border: '1px solid black' }}>No</th>
                                                        <th style={{ border: '1px solid black' }}>File Name</th>
                                                        <th style={{ border: '1px solid black' }}>File Type</th>
                                                        <th style={{ border: '1px solid black' }}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ alignContent: 'center' }}>
                                                    {fileValue.length > 0 ? (
                                                        fileValue.map((file, index) => (
                                                            <tr key={index}>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>{index + 1}</td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.name}</td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                    {file.uploaded ? (
                                                                        <select
                                                                            value={file.type}
                                                                            onChange={(e) => handleFileTypeChange(e, file.id)}
                                                                        >
                                                                            <option value="">Select File Type</option>
                                                                            <option value="Main Report">Main Report</option>
                                                                            <option value="Associated File">Associated File</option>
                                                                            <option value="Additional Info">Additional Info</option>
                                                                            <option value="Certification File">Certification File</option>
                                                                        </select>
                                                                    ) : (
                                                                        file.type
                                                                    )}
                                                                </td>
                                                                <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                    {file.name && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => openRemoveFileModal(file.id.toString())}
                                                                            style={{ border: '1px solid red', borderRadius: '5px', padding: '5px' }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4} style={{ border: '1px solid black', textAlign: 'center', fontSize: '14px' }}>No files available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <Modal
                                    isOpen={isRemoveFileModalOpen}
                                    onRequestClose={closeRemoveFileModal}
                                    contentLabel="Confirm Remove"
                                    style={{
                                        content: {
                                            width: '500px',
                                            height: '200px',
                                            margin: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            border: '1px solid red',
                                        },
                                    }}
                                >
                                    <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                                    <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                                    <p style={{ marginTop: '10px' }}>{removingFileName}</p>
                                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                                        <button
                                            className='usa-button'
                                            onClick={confirmDelete}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className='usa-button'
                                            onClick={closeRemoveFileModal}
                                        >
                                            No
                                        </button>
                                    </div>
                                </Modal>

                                <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <label style={{ marginBottom: '10px' }}>
                                        <b>Checklist:</b>
                                    </label>

                                    <div className="element">
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#d3d3d3' }}>
                                                        <th style={{ border: '1px solid black' }}>Select</th>
                                                        <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ alignContent: 'center' }}>
                                                    <tr>
                                                        <td style={{ border: '1px solid black' }}>
                                                            <input type="checkbox" style={{ margin: '5px', transform: 'scale(1.5)' }} />
                                                        </td>
                                                        <td style={{ border: '1px solid black', fontSize: '14px' }}>Is the report meeting the layout specifications of the report as per latest template?</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="row1">
                    <div className="element" >
                        <p><b>Last Updated By : </b>{recordData?.lastUpdatedBy}</p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{recordData?.lastUpdatedDate}</p>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    );
}