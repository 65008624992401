import './adminDashboard.css'
import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import arrow from '../../img/arrow.png'
import ApplicationIcon from '../../img/applications_icon.png'
import { Link, Route } from 'react-router-dom'
import ApiIcon from '../../img/api.jpg'
import TrackIcon from '../../img/track_status.png'
import ManageDevIcon from '../../img/manage_dev.png'
import AssessmentIcon from '../../img/admin_support.png';
import { useMsal } from '@azure/msal-react'
import AssignmentIcon from '@mui/icons-material/Assignment';
import Box from '@mui/material/Box'
import FormInput from '../../component/Common/FormInput'
import Button from '../../component/Common/Button'
import { useHistory } from 'react-router-dom'
import BackButton from '../../component/Common/BackBtn'
import { Typography } from '@mui/material'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import './adminDashboard.css'
import LockIcon from '../../assets/images/lockIcon.png'
import AdminIcon from '../../assets/images/admin.svg'
import reports from '../../assets/images/reports.svg'
import uploadIcon from '../../img/material-icons/file_upload.svg'
import calendarIcon from '../../assets/images/calendar.svg'
import dashboardIcon from '../../img/usa-icons/report.svg'
import referenceIcon from '../../img/usa-icons/folder.svg'
import userManagementIcon from '../../img/manage_dev.png'
import PageSubTitle from '../../component/Common/PageSubTitle'

const AdminDashboard = () => {
  const { instance, accounts } = useMsal()
  const history = useHistory()
  const orgId = sessionStorage.getItem('orgId')
  const [isLoading, setIsLoading] = useState(false)

  const userType = sessionStorage.getItem('signedInUserType');

  useEffect(() => {
    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      if (userType === null && (!accounts?.[0]?.localAccountId)) {
        history.push('/sign-in');
      }
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [accounts, history, userType]);

  const contractReportRouteFn = () => {
    history.push('/contact-report-invenroty');
  };

  const uploadReportRouteFn = async () => {
    console.log("Attempting to navigate to /upload-reports");
    try {
      history.push('/upload-reports');
      console.log("Navigation to /upload-reports successful");
    } catch (error) {
      console.error("Failed to navigate to /upload-reports", error);
    }
  };

  const remindersRouteFn = async () => {
    history.push('/reminders-notifications');
  };

  const dashboardRouteFn = async () => {
    history.push('/reports-dashboard');
  };
  const referenceRouteFn = async () => {
    history.push('/reference-data');
  };

  const userManagementRouteFn = async () => {
    history.push('/user-management');
  };

  const sftpUploadedRouteFn = async () => {
    history.push('/sftp-uploaded-reports');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      ) : (
        <React.Fragment>
          <div className="flex-grow">
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <PageSubTitle text={'Welcome to Nebraska Managed Care Insight System(NEMCIS)'} />
              </div>
            </div>

            <div className="home_page_css" style={{ display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                  },
                  gap: 3,
                  justifyContent: 'center',

                  '& > :not(style)': {
                    m: 1,
                    width: { xs: '100%', sm: 260 },
                    height: { xs: 'auto', sm: 175 },
                    maxWidth: 260,
                    maxHeight: 175,
                    transition: 'box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      boxShadow: '0 0 20px rgba(0, 123, 255, 0.5)',
                    },
                  },
                  my: 0,
                  m: 0,
                }}
              >
                {/* Contract Report Inventory */}
                <div
                  onClick={contractReportRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={reports} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                    <p className="leading-5 mb-3 text-black">Report Inventory</p>
                    <div className="flex gap-2">
                      <p className="text-xs">Track the scheduled and submitted reports</p>
                    </div>
                  </div>
                </div>

                {/* Upload Reports and Data */}
                {userType && (
                  <>
                    <div
                      onClick={uploadReportRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={uploadIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">Upload Reports and Data</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Upload your required report or
                            data via the secure portal</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Reminders and Notifications */}
                <div
                  onClick={remindersRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={calendarIcon} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2 ml-2">
                    <p className="leading-5 mb-3 text-black">Alerts and Notifications</p>
                    <div className="flex gap-2">
                      <p className="text-xs">Alerts and Notifications for user actions</p>
                    </div>
                  </div>
                </div>

                {/* Dashboard */}
                {accounts?.[0]?.localAccountId && (
                  <>
                    <div
                      // onClick={dashboardRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={dashboardIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">Dashboard</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Compare Overall Plan Compliance Performance</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Reference */}
                <div
                  // onClick={referenceRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={referenceIcon} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                    <p className="leading-5 mb-3 text-black">Reports Master Catalog</p>
                    <div className="flex gap-2">
                      <p className="text-xs"> Click here to access the required reports, associated templates and submission requirements</p>
                    </div>
                  </div>
                </div>

                {/* User Management */}
                {accounts?.[0]?.localAccountId && (
                  <>
                    <div
                      // onClick={userManagementRouteFn}
                      className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                    >
                      <img src={userManagementIcon} alt="lock" width='100px' className="self-start" />

                      <div className="space-y-2 mb-16 flex flex-col justify-between ml-2">
                        <p className="leading-5 mb-3 text-black">User Management</p>
                        <div className="flex gap-2">
                          <p className="text-xs">Managing Users and their access
                            priveleges</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* SFTP Reports */}
                <div
                  onClick={sftpUploadedRouteFn}
                  className={`border-[7px] border-[#bac6de] cursor-pointer text-primary md:text-lg text-md md:font-medium font-normal rounded-3xl md:py-5 py-2 px-2 w-80 flex flex-row items-start justify-between`}
                >
                  <img src={uploadIcon} alt="lock" width='100px' className="self-start" />

                  <div className="space-y-2 mb-16 flex flex-col justify-between ml-2 ml-2">
                    <p className="leading-5 mb-3 text-black">Manage SFTP Reports</p>
                    <div className="flex gap-2">
                      <p className="text-xs">Link/Unlink the submitted SFTP reports</p>
                    </div>
                  </div>
                </div>

              </Box>

            </div>
          </div>
          <div style={{ marginTop: 'auto' }}>
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
export default AdminDashboard
