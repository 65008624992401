import React, { useRef, useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './upload-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';

const AdhocOrNewOnDemandReport = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [fileName, setFileName] = useState('');
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedMCONameDropdown, setSelectedMCONameDropdown] = useState('');
    const [defaultsSet, setDefaultsSet] = useState(false);
    const [uploadNewReport, setUploadNewReport] = useState<any>({
        mcoName: '',
        reportName: '',
        referenceNumber: '',
        channel: '',
        reportType: '',
        startDate: '',
        endDate: '',
        dueDate: '',
        userComments: '',
        submissinStatus: 'Scheduled',
        currentGroup: 'MC Control Group',
        currentSubmission: '1',
        reviewStatus: 'In Review',
        lastUpdatedBy: accounts[0]?.name || 'MCO User',
        lastUpdatedDate: moment(new Date()).format('MM/DD/YYYY hh:mm A'),
    });

    const userType = sessionStorage.getItem('signedInUserType');

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType == 'HealthPlanUser') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    mcoName: 'Molina',
                    reportType: 'ondemand',
                    reportName: 'VettingReport',
                    referenceNumber: 'NEB_AH_001',
                }));
            }
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [userType]);

    function validateForm(): void {
        if (
            // !uploadNewReport.mcoName ||
            !uploadNewReport.reportType ||
            !uploadNewReport.reportName ||
            !uploadNewReport.dueDate ||
            !uploadNewReport.startDate ||
            !uploadNewReport.endDate ||
            // !uploadNewReport.referenceNumber ||
            !uploadNewReport.channel
        ) {
            setIsFormValid(false)
        } else {
            setIsFormValid(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [uploadNewReport])

    function handleInputData(event: any) {
        const { name, value } = event.target;

        if (name === 'reportType') {
            if (value === 'ondemand') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    ['referenceNumber']: 'NEB_AH_001',
                    ['reportName']: 'VettingReport',
                    [name]: value
                }));
            } else {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    ['referenceNumber']: '',
                    ['reportName']: '',
                    [name]: value
                }));
            }
        } else {
            setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));
        }
    }

    function handleDateInputChange(event: any) {
        const { name, value } = event.target
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
        setUploadNewReport((prevState: any) => ({ ...prevState, ['userComments']: appDetails }))
    }

    const reportTypeDropdown = [
        { label: 'Ad hoc', value: 'adhoc' },
        { label: 'On demand', value: 'ondemand' },

    ];

    const filteredReportTypeDropdown = userType === 'HealthPlanUser'
        ? reportTypeDropdown.filter(option => option.value === 'ondemand')
        : reportTypeDropdown;

    const channelDropdown = [
        { label: 'Web', value: 'WEB' },
        { label: 'SFTP', value: 'SFTP' }
    ];

    const reportNameDropdown = [
        { label: 'Vetting Report', value: 'VettingReport' },
    ];

    const mcoNameDropdown = [
        { label: 'United Health', value: 'unitedHealth' },
        { label: 'Nebraska Total Plan', value: 'nebraskaTotalPlan' },
        { label: 'Molina', value: 'Molina' },
        { label: 'All', value: 'All' },
    ];

    function handleClear() {
        history.goBack()
    }

    function handleSave() {
        setLoadingButton(true)
        uploadNewReport.period = `${moment(uploadNewReport.startDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.endDate).format('MM/DD/YYYY')}`

        const existingData = localStorage.getItem('uploadNewReportData');
        let dataArray = [];
        if (existingData) {
            dataArray = JSON.parse(existingData);
        }

        // Iterate over selectedValues and create a record for each selected value
        if (userType === 'HealthPlanUser') {
            dataArray.push(uploadNewReport);
        } else {
            selectedValues.forEach((value) => {
                const newReport = { ...uploadNewReport, mcoName: value };
                dataArray.push(newReport);
            });
        }

        const uploadNewDataToSave = JSON.stringify(dataArray);
        localStorage.setItem('uploadNewReportData', uploadNewDataToSave);
        alert('New Report data has been successfully uploaded');
        setLoadingButton(false)
        history.push('/admin-dashboard');
    }

    const handleMultiDropdownChange = (selectedOptions: string[]) => {
        setSelectedValues(selectedOptions)
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text={"Add Report"} />
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <Dropdown
                        choices={filteredReportTypeDropdown}
                        emptyChoiceLabel="Select Filter Criteria 1"
                        name="reportType"
                        label="Report Type"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.reportType || ''}
                        onChange={handleInputData}
                        mandatoryLabel={userType === 'HealthPlanUser' ? false : true}
                        disabled={userType === 'HealthPlanUser' ? true : false}
                    />
                </div>
                <div className="element" >
                    {userType === 'HealthPlanUser' ? (
                        <Dropdown
                            choices={mcoNameDropdown}
                            emptyChoiceLabel="Select MCO Name"
                            name="mcoName"
                            label="MCO Name"
                            data-testid="mco-name"
                            value={uploadNewReport.mcoName || ''}
                            onChange={handleInputData}
                            mandatoryLabel={userType === 'HealthPlanUser' ? false : true}
                            disabled={userType === 'HealthPlanUser' ? true : false}
                        />
                    ) : (
                        <div className="usa-form-group" >
                            <MultiSelectDropdown
                                options={mcoNameDropdown}
                                label="MCO Name"
                                name="mcoName"
                                onChange={handleMultiDropdownChange}
                                selectedValues={selectedValues}
                                mandatoryLabel={true}
                            />
                        </div>
                    )}

                </div>
            </div>
            <div className="row1">
                <div className="element" >
                    {uploadNewReport.reportType === 'adhoc' ? (
                        <InputText
                            context="registration"
                            label="Report Name"
                            name="reportName"
                            placeholder="Report Name"
                            value={uploadNewReport.reportName || ''}
                            onChange={handleInputData}
                            mandatoryLabel={true}
                        />
                    ) : (
                        <Dropdown
                            choices={reportNameDropdown}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="reportName"
                            label="Report Name"
                            data-testid="payment_type_testid"
                            value={uploadNewReport.reportName || ''}
                            onChange={handleInputData}
                            mandatoryLabel={true}
                        />
                    )}
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Due Date"
                            type="date"
                            name="dueDate"
                            value={uploadNewReport.dueDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting Start Date"
                            type="date"
                            name="startDate"
                            value={uploadNewReport.startDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting End Date"
                            type="date"
                            name="endDate"
                            value={uploadNewReport.endDate}
                            onChange={handleDateInputChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                </div>
            </div>
            <div className="row1">
                <div className="element" >
                    <InputText
                        context="registration"
                        label="Reference Number"
                        name="referenceNumber"
                        placeholder="Reference Number"
                        value={uploadNewReport.referenceNumber}
                        onChange={handleInputData}
                        // mandatoryLabel={true}
                        disabled={uploadNewReport.reportType === 'ondemand' ? true : false}
                    // small={true}
                    />
                </div>

                <div className="element">
                    <Dropdown
                        choices={channelDropdown}
                        emptyChoiceLabel="Select Channel"
                        name="channel"
                        label="Channel"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.channel || ''}
                        onChange={handleInputData}
                        mandatoryLabel={true}
                    />
                </div>
            </div>

            <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                <label >
                    <b>User Comments:</b>
                </label>
                <ReactQuill
                    style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                    value={uploadNewReport.userComments}
                    onChange={handleRichTextChangeAppDetails}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                            [{ align: [] }], // alignment options
                            ['clean'], // remove formatting option
                        ],
                    }}
                />
            </div>
            <div className="row1">
                <div className="element" />
                <div className="external-filters-action flex justify-end w-full lg:w-auto lg:max-w-xs">
                    <div>
                        <Button
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isFormValid}
                            className="OkButton"
                            onClick={handleSave}
                            loading={loadingButton}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}
export default AdhocOrNewOnDemandReport;