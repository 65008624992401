import React, { useState, useEffect, useRef } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import _, { set } from 'lodash'
import './contract-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import Modal from 'react-modal';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';

export default function StateReviewPage() {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const [isRemoveFileModalOpen, setRemoveFileModalOpen] = useState(false);
    const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);
    const userType = sessionStorage.getItem('signedInUserType');
    const gridStyle = { minHeight: 340, width: '100%' };
    const [isReviewOptionsModalOpen, setIsReviewOptionsModalOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    interface ContractReport {
        referenceNumber: string;
        mcoName: string;
        reportName?: string;
        frequency: string;
        reportType: string;
        startDate: string;
        endDate: string;
        channel: string;
        dueDate: string;
        submissinStatus: string;
        reviewStatus: string;
        currentSubmission: string;
        currentSubmissionStatus: string;
        currentgroup: string;
        submissionDate: string;
        period: string;
        submissionNumber: string;
        reviewNumber: string;
        lastUpdatedBy: string;
        lastUpdatedDate: string;
        file: string;
        uploadFiles: string;
    };

    interface LocationState {
        recordData?: ContractReport;
        detailsPageReferenceFalg?: boolean;
    }

    const recordData = (location?.state as LocationState)?.recordData || null;
    const detailsPageReferenceFalg = (location?.state as LocationState)?.detailsPageReferenceFalg || false;

    function handleCancel() {
        history.goBack()
    }

    function handleSaveButton() {
    }

    interface FileWithMetadata {
        id: number;
        name: string;
        type: string;
        mcoViewable: string;
        uploaded: boolean;
    }

    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const reviewerfileInputRef = useRef<HTMLInputElement>(null);

    const [fileValue, setFileValue] = useState<any[]>([]);
    const [removingFileName, setRemovingFileName] = useState('');

    useEffect(() => {
        if (recordData) {
            const uploadedFiles = Array.isArray(recordData.uploadFiles) ? recordData.uploadFiles : [];
            setFileValue(uploadedFiles);
        }
    }, [recordData]);

    const submissionStatusDropdown = [
        { label: 'Scheduled', value: 'Scheduled' },
        { label: 'Resubmitted', value: 'Resubmitted' },
        { label: 'Past Due', value: 'Resubmission' },
        { label: 'Closed', value: 'Closed' },
        { label: 'Submitted', value: 'Submitted' },
        { label: 'Reopened', value: 'Reopened' },
    ];

    const handleRichTextChangeNotes = (event: any, name: any) => {
        // setBusinessOutcome((prevState: any) => ({ ...prevState, [name]: event }))
    }

    function handleGeneralInputData(event: any) {
        const { name, value } = event.target
        // setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const formatDateForInput = (dateString: string) => {
        let parts;
        if (dateString.includes('/')) {
            parts = dateString.split('/');
            return `${parts[2]}-${parts[0]}-${parts[1]}`;
        } else if (dateString.includes('-')) {
            parts = dateString.split('-');
            return `${parts[0]}-${parts[1]}-${parts[2]}`;
        }
        return '';
    };

    const viewUploadedDocs = (event: any) => {
        const documentUrl = '/assets/docs/ScreenMockUp-ManagedCareCollabTool.pdf';
        window.open(documentUrl, '_blank');
    }

    const formattedStartDate = recordData?.startDate ? formatDateForInput(recordData.startDate) : '';
    const formattedEndDate = recordData?.endDate ? formatDateForInput(recordData.endDate) : '';
    const formattedSubmissionDate = recordData?.submissionDate ? formatDateForInput(recordData.submissionDate) : '';
    const formattedDueDate = recordData?.dueDate ? formatDateForInput(recordData.dueDate) : '';
    const formattedsubmissionDate = recordData?.submissionDate ? formatDateForInput(recordData.submissionDate) : '';
    const [reviewModalData, setReviewModalData] = useState<ReviewerActions | null>(null);

    const submissionReviewDropdown = [
        { label: 'Accepted', value: 'Accepted' },
        { label: 'In Review', value: 'In Review' },
        { label: 'Reassigned', value: 'Reassigned' },
        { label: 'Rejected', value: 'Rejected' },

    ];

    const chooseReviewDecisionDropdown = reviewModalData?.reviewerGroup.includes('Final Reviewer') ? [
        { label: 'Request Additional Information', value: 'Request Additional Information' },
        { label: 'Reject', value: 'SubRejectmit' },
        { label: 'Accept', value: 'Accept' },

    ] : [
        { label: 'Recommend Followup', value: 'Recommend Followup' },
        { label: 'Recommend Reject', value: 'Recommend Reject' },
        { label: 'Recommend Accept', value: 'Recommend Accept' },
        { label: 'Other', value: 'Other' },
    ];
    const [reviewDecision, setReviewDecision] = useState('');

    const handleReviewDecisionDropdownData = (event: any) => {
        const { name, value } = event.target
        setReviewDecision(value)
    }

    const openRemoveFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setRemovingFileName(fileValue.find(f => f.id === fileId)?.name || '');
    };

    const closeRemoveFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setRemovingFileName('')
    };

    const openRemoveReviewerFileModal = (fileId: any) => {
        setFileIdToDelete(fileId);
        setRemoveFileModalOpen(true);
        setReviewerRemovingFileName(reviewerFileValue.find(f => f.id === fileId)?.name || '');
    };

    const closeRemoveReviewerFileModal = () => {
        setRemoveFileModalOpen(false);
        setFileIdToDelete(null);
        setReviewerRemovingFileName('')
    };

    const confirmReviewerDelete = () => {
        if (fileIdToDelete !== null) {
            handleReviewerRemoveFile(fileIdToDelete);
        }
        closeRemoveFileModal();
    };

    interface ReviewerActions {
        reviewerGroup: string;
        decisionStatus: string;
        updatedDate: string;
        lastUpdatedBy: string;
    };

    const reviewerActionsHeaders: any[] = [
        {
            name: 'reviewerGroup',
            header: (
                <div className='grid-header-custom-style'>
                    Reviewer Group
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
        },
        {
            name: 'decisionStatus',
            header: (
                <div className='grid-header-custom-style'>
                    Decision - Status
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'lastUpdatedBy',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Last Updated By
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
        },
        {
            name: 'action',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Action
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 100,
            maxWidth: 130,
            render: ({ data }: { data: ReviewerActions }) => {
                const handleColumnClick = () => {
                    setReviewModalData(data)
                    setIsReviewOptionsModalOpen(true)
                };

                return (
                    <Button className='okButton' onClick={handleColumnClick}>Update</Button>
                );
            },
        }
    ]

    const reviewerActionsInitialData: ReviewerActions[] = [
        {
            reviewerGroup: 'Data Analysts',
            decisionStatus: 'Recommend Accept - Completed',
            updatedDate: '07/17/2024  05:21:00 PM',
            lastUpdatedBy: 'bperry@nebraska.gov'
        },
        {
            reviewerGroup: 'Data Analysts - Aggregation Scripts',
            decisionStatus: 'Recommend Accept - Completed',
            updatedDate: '07/17/2024  05:26:00 PM',
            lastUpdatedBy: 'bperry@nebraska.gov'
        },
        {
            reviewerGroup: 'Plan Management: Program Specialist',
            decisionStatus: '',
            updatedDate: '',
            lastUpdatedBy: ''
        },
        {
            reviewerGroup: 'Final Reviewer',
            decisionStatus: '',
            updatedDate: '',
            lastUpdatedBy: ''
        },
    ];

    let [reviewerActionsDataList, setReviewerActionsDataList] = useState<ReviewerActions[]>(reviewerActionsInitialData);

    const closeReviewModal = () => {
        setIsReviewOptionsModalOpen(false);
        setReviewDecision('')
    };

    const [reviewerFileValue, setReviewerFileValue] = useState<FileWithMetadata[]>([]);
    const [reviewerRemovingFileName, setReviewerRemovingFileName] = useState('');

    const handleReviewerRemoveFile = (fileId: string) => {
        setReviewerFileValue(prevFiles => prevFiles.filter(f => f.id !== Number(fileId)));
    };

    const handleReviewerFileUploadButtonClick = () => {
        if (reviewerfileInputRef.current) {
            reviewerfileInputRef.current.click();
        }
    };

    const handleReviewerFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const newFiles = Array.from(files).map(file => ({
                id: Date.now(),
                name: file.name,
                type: '',
                mcoViewable: '',
                uploaded: true
            }));
            setReviewerFileValue(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const handleReviewerFileTypeChange = (e: any, fileId: any) => {
        const fileType = e.target.value;
        setReviewerFileValue((prevFiles) =>
            prevFiles.map((f) =>
                f.id === fileId ? { ...f, type: fileType } : f
            )
        );
    };

    const handleReviewerMcoViewableChange = (e: any, fileId: any) => {
        const mcoViewable = e.target.value;
        setReviewerFileValue((prevFiles) =>
            prevFiles.map((f) =>
                f.id === fileId ? { ...f, mcoViewable: mcoViewable } : f
            )
        );
    };
    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="State Review" />
                </div>
            </div>

            <div className="form-layout">
                {/* <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={handleSaveButton}
                    >
                        Save
                    </Button>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={handleSaveButton}
                    >
                        Submit
                    </Button>
                    <Button
                        className="OkButton"
                        disabled={detailsPageReferenceFalg ? true : false}
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                </div> */}

                <div className="details-block">
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="MCO Name"
                                name="mcoName"
                                value={recordData?.mcoName}
                                onChange={handleGeneralInputData}
                                disabled={true}

                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="reportName"
                                value={recordData?.reportName}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Reporting Type"
                                name="reportType"
                                value={recordData?.reportType}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                context="registration"
                                label="Submission Channel"
                                name="channel"
                                value={recordData?.channel}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Period"
                                name="period"
                                value={recordData?.period}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Due Date"
                                    type="date"
                                    name="dueDate"
                                    value={formattedDueDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={submissionStatusDropdown}
                                emptyChoiceLabel="Select submission status"
                                name="submissinStatus"
                                label="Current Submission Status"
                                data-testid="payment_type_testid"
                                value={recordData?.submissinStatus}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <FormInput
                                    label="Last Submission Date"
                                    type="date"
                                    name="submissionDate"
                                    value={formattedsubmissionDate}
                                    onChange={handleGeneralInputData}
                                    placeholder={'MM-DD-YYYY'}
                                    id={''}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <Dropdown
                                choices={submissionReviewDropdown}
                                emptyChoiceLabel="Select submission status"
                                name="currentReviewStatus"
                                label="Current Review Status"
                                data-testid="payment_type_testid"
                                value={recordData?.reviewStatus}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <InputText
                                label="Last Updated Date"
                                name="lastUpdatedDate"
                                value={recordData?.lastUpdatedDate}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row1">
                        <div className="element" >
                            <InputText
                                label="Submission#"
                                name="submissionNumber"
                                value={recordData?.currentSubmission}
                                onChange={handleGeneralInputData}
                                disabled={true}
                            />
                        </div>
                        <div className="element" >
                            <div className="usa-form-group">
                                <InputText
                                    label="Review#"
                                    name="reviewNumber"
                                    value={recordData?.reviewNumber}
                                    onChange={handleGeneralInputData}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="details-block">
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label >
                            <b>Reviewer's Actions:</b>
                        </label>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={reviewerActionsHeaders}
                            dataSource={reviewerActionsDataList}
                            sortable={true}
                            defaultLimit={5}
                            rowHeight={50}
                            enableColumnAutosize={true}

                        />
                    </div>
                    {/* {Reviewer Internal Notes} */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label >
                            <b>Reviewer's Internal Notes Timeline:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value={
                                `<p>07/17/2024 05:26:24 PM - BP (DA-AS) -> Data validation completed and all the aggregated totals matching</p>
                                <p>07/17/2024 05:21:07 PM - BP (DA) -> Data found as per the layout</p>`
                            }
                            className={"greyed-out"}
                            readOnly={true}
                            onChange={(ev) =>
                                handleRichTextChangeNotes(ev, 'description')
                            }
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    [{ align: [] }],
                                    ['clean'],
                                ],
                            }}
                        />
                    </div>

                    {!detailsPageReferenceFalg && (
                        <>
                            <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                <label style={{ marginBottom: '10px' }}>
                                    <b>Reviewer Attachments, if any required:</b>
                                </label>
                                <div className="element" style={{ marginTop: 10 }}>

                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr style={{ backgroundColor: '#d3d3d3' }}>

                                                    <th style={{ border: '1px solid black' }}>Submission Date</th>
                                                    <th style={{ border: '1px solid black' }}>Submission #</th>
                                                    <th style={{ border: '1px solid black' }}>File Name</th>
                                                    <th style={{ border: '1px solid black' }}>File Type</th>
                                                    <th style={{ border: '1px solid black' }}>MCO Viewable</th>
                                                    <th style={{ border: '1px solid black' }}>Download/View</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ alignContent: 'center' }}>
                                                {fileValue.length > 0 ? (
                                                    fileValue.map((file, index) => (
                                                        <tr key={index}>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.submissionDate}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.submission}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.name}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.type}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.mcoViewable}</td>
                                                            <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                                <a href={file?.name} target="_blank" rel="noopener noreferrer" className="file-link" style={{ display: 'inline-flex', margin: '5px' }}>
                                                                    View File <FaExternalLinkAlt style={{ marginLeft: '5px' }} />
                                                                </a>
                                                                <span style={{ margin: '5px' }}>or</span>
                                                                <a href={file?.name} download className="file-link" style={{ display: 'inline-flex', margin: '5px' }}>
                                                                    Download <FaDownload style={{ marginLeft: '5px' }} />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={5} style={{ border: '1px solid black', textAlign: 'center' }}>No files available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* Reviewer Options Modal */}
                <Modal
                    isOpen={isReviewOptionsModalOpen}
                    onRequestClose={closeReviewModal}
                    contentLabel="Reviewr Options"
                    className="OverlayMainContainer"
                    overlayClassName="OverlayContainer"

                >
                    <div style={{ fontSize: '20px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <h2><b>{reviewModalData?.reviewerGroup} - Review</b></h2>
                    </div>

                    {/* {Decision} */}
                    <div className="element" >
                        <Dropdown
                            choices={chooseReviewDecisionDropdown}
                            emptyChoiceLabel="Select action"
                            name="reviewDecision"
                            label="Decision"
                            data-testid="payment_type_testid"
                            value={reviewDecision}
                            onChange={handleReviewDecisionDropdownData}
                            mandatoryLabel={true}
                            disabled={detailsPageReferenceFalg ? true : false}
                        />
                    </div>

                    {/* {Notes} */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Notes:</b>
                        </label>
                        <ReactQuill
                            style={{ maxHeight: '500px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                            value='Test Add Notes for the MCO'
                            className={detailsPageReferenceFalg ? "greyed-out" : ''}
                            readOnly={detailsPageReferenceFalg ? true : false}
                            onChange={(ev) =>
                                handleRichTextChangeNotes(ev, 'description')
                            }
                            modules={{
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    [{ align: [] }],
                                    ['clean'],
                                ],
                            }}
                        />
                        <p style={{ fontSize: '10px' }}>Only Final Reviewer's notes will be viewable to MCO</p>
                    </div>

                    {/*Upload files */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <div className="external-filters-action flex flex-row justify-end w-full md:w-auto" style={{ marginBottom: '-30px' }}>
                            <Button
                                className="OkButton"
                                onClick={handleReviewerFileUploadButtonClick}
                            >
                                Upload File
                            </Button>
                            <input
                                type="file"
                                ref={reviewerfileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleReviewerFileChange}
                            />
                        </div>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Reviewer Attachments, if any required:</b>
                        </label>
                        <div className="element">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>No</th>
                                            <th style={{ border: '1px solid black' }}>File Name</th>
                                            <th style={{ border: '1px solid black' }}>File Type</th>
                                            <th style={{ border: '1px solid black' }}>MCO Viewable</th>
                                            <th style={{ border: '1px solid black' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        {reviewerFileValue.length > 0 ? (
                                            reviewerFileValue.map((file, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{index + 1}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>{file.name}</td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                        {file.uploaded ? (
                                                            <select
                                                                value={file.type}
                                                                onChange={(e) => handleReviewerFileTypeChange(e, file.id)}
                                                            >
                                                                <option value="">Select File Type</option>
                                                                <option value="Validation Report">Validation Report</option>
                                                                <option value="Others">Others</option>

                                                            </select>
                                                        ) : (
                                                            file.type
                                                        )}
                                                    </td>
                                                    <td style={{ border: '1px solid black', fontSize: '14px' }}>
                                                        {file.uploaded ? (
                                                            <select
                                                                value={file.mcoViewable}
                                                                onChange={(e) => handleReviewerMcoViewableChange(e, file.id)}
                                                            >
                                                                <option value="">Select MCO Viewable</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>

                                                            </select>
                                                        ) : (
                                                            file.mcoViewable
                                                        )}
                                                    </td>                                                    <td style={{ border: '1px solid black' }}>
                                                        {file.name && (
                                                            <button
                                                                type="button"
                                                                onClick={() => openRemoveReviewerFileModal(file.id.toString())}
                                                                style={{ border: '1px solid red', borderRadius: '5px', padding: '5px', fontSize: '14px' }}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5} style={{ border: '1px solid black', textAlign: 'center', fontSize: '14px' }}>No files available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={isRemoveFileModalOpen}
                        onRequestClose={closeRemoveFileModal}
                        contentLabel="Confirm Remove"
                        className="OverlayMainContainer"
                        overlayClassName="OverlayContainer"
                        style={{
                            content: {
                                width: '500px',
                                height: '200px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                border: '1px solid red',
                            },
                        }}
                    >
                        <h2><b style={{ fontSize: '20px' }}>Confirm Remove</b></h2>
                        <p style={{ marginTop: '10px', fontSize: '18px', }}>Are you sure you want to delete this file?</p>
                        <p style={{ marginTop: '10px' }}>{reviewerRemovingFileName}</p>
                        <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                            <button
                                className='usa-button'
                                onClick={confirmReviewerDelete}
                            >
                                Yes
                            </button>
                            <button
                                className='usa-button'
                                onClick={closeRemoveReviewerFileModal}
                            >
                                No
                            </button>
                        </div>
                    </Modal>

                    {/* {Checklist} */}
                    <div className="usa-form-group" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                        <label style={{ marginBottom: '10px' }}>
                            <b>Checklist:</b>
                        </label>

                        <div className="element">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <table style={{ backgroundColor: '#f0f0f0', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#d3d3d3' }}>
                                            <th style={{ border: '1px solid black' }}>Select</th>
                                            <th style={{ border: '1px solid black' }}>Checklist Item</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ alignContent: 'center' }}>
                                        <tr>
                                            <td style={{ border: '1px solid black' }}>
                                                <input type="checkbox" style={{ margin: '5px', transform: 'scale(1.5)' }} />
                                            </td>
                                            <td style={{ border: '1px solid black' }}>Is the report meeting the layout specifications of the report as per latest template?</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            className='usa-button'
                            onClick={closeReviewModal}
                        >
                            Save
                        </button>
                        <button
                            className='usa-button'
                            onClick={closeReviewModal}
                        >
                            Submit
                        </button>
                        <button
                            className='usa-button'
                            onClick={closeReviewModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="row1" style={{ marginTop: '10px' }}>
                        <div className="element" >
                            <p><b>Last Updated By : </b>{reviewModalData?.lastUpdatedBy}</p>
                        </div>
                        <div className="element" >
                            <p><b>Last Updated Date : </b>{reviewModalData?.updatedDate}</p>
                        </div>
                    </div>
                </Modal>

                <div className="row1">
                    <div className="element" >
                        <p><b>Last Updated By : </b>{recordData?.lastUpdatedBy}</p>
                    </div>
                    <div className="element" >
                        <p><b>Last Updated Date : </b>{recordData?.lastUpdatedDate}</p>
                    </div>
                </div>
            </div >
            <div>
                <Footer />
            </div>
        </>
    );
}