import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import FormInput from '../../component/Common/FormInput'
import InputText from '../../component/Common/InputText'
import Button from '../../component/Common/Button'
import { useHistory } from 'react-router-dom'
import BackButton from '../../component/Common/BackBtn'
import { Typography } from '@mui/material'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import PageSubTitle from '../../component/Common/PageSubTitle'
import './user-sign-in.css'
import Dropdown from "../../component/Common/Dropdown";

const AppSignUpPage = (props: any) => {
  interface Credentials {
    name: string;
    email: string;
    phoneNumber: string;
    userId: string;
    password: string;
    userType: string;
  }
  const history = useHistory()

  const [userIdError, setuserIdError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [inputEmail, setInputEmail] = useState('');

  const [signupData, setSignupData] = useState<Credentials>({
    name: '',
    email: '',
    phoneNumber: '',
    userId: '',
    password: '',
    userType: '',
  })
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  function validateForm() {
    if (signupData.name && signupData.email && signupData.phoneNumber &&
       signupData.userId && signupData.password && signupData.userType) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }

  useEffect(() => {
    validateForm();
  }, [signupData]);

  useEffect(() => {
    validateForm();
  }, [isEmailValid]);

  function isValidEmail(email: any) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }


  const signupButtonFunction = () => {
    sessionStorage.setItem('signedUpUserData', JSON.stringify(signupData))
    sessionStorage.setItem('signedInUserType', signupData.userType)
    history.push('/admin-dashboard')
  }

  function handleAdditionalChange(event: any): void {
    const { name, value } = event.target
    if (name === "email") {
      setIsEmailValid(isValidEmail(value));
      setSignupData({
        ...signupData,
        [event.target.name]: event.target.value,
      })
      setInputEmail(value);
    } else {
      setSignupData({
        ...signupData,
        [event.target.name]: event.target.value,
      })
    }
  }

  const loginValidationFormWithErrorMsg = () => {
    let isFormValid = true

    if (!signupData.userId) {
      setuserIdError('Please enter User Name field')
      isFormValid = false
    }

    if (!signupData.password) {
      setPasswordError('Please enter Password field')
      isFormValid = false
    }

    return isFormValid
  }

  const userTypeDropdownChoices = [
    {
      label: "01-Health Plan",
      value: "HealthPlan",
    },
    {
      label: "02-State",
      value: "State",
    },
    {
      label: "03-Other",
      value: "Other",
    }
  ];

  return (
    <>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ alignSelf: 'flex-start' }}>
          <BackButton />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <PageSubTitle text="Sign Up New User" />
        </div>
      </div>
      <div className="flex-grow">
        <div>
          <Box sx={{ m: 20, maxWidth: '550px', margin: '30px auto' }}>
            <Box sx={{ m: 6 }}>

              <InputText
                label="Name"
                name="name"
                placeholder="User Name"
                value={signupData?.name}
                onChange={handleAdditionalChange}
                mandatoryLabel={true}
              // small={true}
              />

              <InputText
                label="Email Address"
                name="email"
                placeholder="Email Address"
                value={inputEmail}
                type='text'
                onChange={handleAdditionalChange}
                mandatoryLabel={true}
              />{!isEmailValid && (
                <div className="errorMessage">
                  Email is Invalid
                </div>
              )}

              <InputText
                name="phoneNumber"
                label="Phone Number"
                type='text'
                placeholder="+1(123)-456 7890"
                value={signupData?.phoneNumber}
                onChange={handleAdditionalChange}
                mandatoryLabel={true}
                mask={'+1\\(999)-999 9999'}
              />

              <InputText
                label="User Id"
                name="userId"
                placeholder="User Id"
                value={signupData?.userId}
                type='text'
                onChange={handleAdditionalChange}
                mandatoryLabel={true}
              />

              <InputText
                label="Password"
                name="password"
                placeholder="password"
                value={signupData?.password}
                type={'password'}
                onChange={handleAdditionalChange}
                mandatoryLabel={true}
              />

              <Dropdown
                choices={userTypeDropdownChoices}
                emptyChoiceLabel="Select User Type"
                label="User Type"
                name="userType"
                data-testid="user_type_testid"
                value={signupData.userType}
                mandatoryLabel={true}
                onChange={handleAdditionalChange}
              />

              <Button
                disabled={!isFormValid}
                onClick={signupButtonFunction}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default AppSignUpPage
