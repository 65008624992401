import React from 'react'
import { useHistory} from 'react-router-dom'
import { BiLeftArrowAlt, BiHome } from 'react-icons/bi'

const BackBtn = () => {
  const history = useHistory()

  const goBack = () => {
    history.goBack() // This navigates back to the previous page
  }

  const goHome = () => {
    history.push('/admin-dashboard') // This navigates back to the previous page
  }

  return (
    <div className="relative flex top-5 gap-6 left-5">
      <div
        className="flex justify-center items-center gap-1 text-primary text-md first-letter"
        onClick={() => history.goBack()}
        style={{ cursor: 'pointer' }}
      >
        <BiLeftArrowAlt />
        <div className="underline">Back</div>
      </div>
      <div
        className="flex  justify-center items-center gap-1 text-primary text-md first-letter"
        onClick={()=>  history.push('/admin-dashboard')}
        style={{ cursor: 'pointer' }}
      >
        <BiHome />
        <div className="underline">Home</div>
      </div>
    </div>
  )
}

export default BackBtn
