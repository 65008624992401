import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Logo from '../img/nebraska-txt.png'

const Navbar = () => {
  const { instance, accounts } = useMsal();

  const history = useHistory();
  const [signedIn, setSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [logoutBuutonIsHovered, setlogoutBuutonIsHovered] = useState(false);
  const signedInUserType = sessionStorage.getItem('signedInUserType');

  const signedInUserData = sessionStorage.getItem('signedUpUserData');
  let loggedInUserName = signedInUserData ? JSON.parse(signedInUserData).name : null;

  const userType = sessionStorage.getItem('signedInUserType');

  const msalLogOutHandle = () => {

    // history.push(path);
    setSignedIn(false)

    sessionStorage.removeItem('signedInUserType')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('orgId')
    sessionStorage.removeItem('token_type')
    sessionStorage.removeItem('member_id')
    sessionStorage.removeItem('isIdProofed')
    instance.logoutRedirect({
      account: accounts[0],
      postLogoutRedirectUri: "/sign-in",
    })
  };

  const userLogoutHandle = () => {
    sessionStorage.removeItem('signedInUserType')
    sessionStorage.removeItem('signedUpUserData')
    sessionStorage.removeItem('access_token')
    sessionStorage.removeItem('orgId')
    sessionStorage.removeItem('token_type')
    sessionStorage.removeItem('member_id')
    sessionStorage.removeItem('isIdProofed')
    history.push("/sign-in")
  };

  const logoutButtonStyle = {
    width: '100px',
    border: logoutBuutonIsHovered ? '1px solid red' : '1px solid white',
    color: 'white',
    marginTop: '10px',
    fontWeight: 'bold',
    borderRadius: '3px',
    padding: '1.0rem',
    lineHeight: '1px',
  };

  return (
    <div>
      <div>
        <div className="header">
        <a className="logo" href={userType || (accounts?.[0]?.localAccountId) ? '/admin-dashboard' : undefined}>
            {/* <img src={Logo} alt="NEMCIS" className="w-24 sm:w-48"   /> */}
            <h2 title="Managed Care Program-Observability">NEMCIS</h2>
          </a>
          <div className="user-info">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {accounts && accounts[0] && (
                <>
                  <span className='header-user-info-span'>
                    Welcome: {accounts[0].name}
                  </span>
                  <button
                    onClick={msalLogOutHandle}
                    onMouseEnter={() => setlogoutBuutonIsHovered(true)}
                    onMouseLeave={() => setlogoutBuutonIsHovered(false)}
                    style={logoutButtonStyle}
                  >
                    Logout
                  </button>
                </>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {signedInUserType && (
                <>
                  <span className='header-user-info-span'>
                    Welcome: {"Test User, Molina"}
                  </span>
                  <button
                    onClick={userLogoutHandle}
                    onMouseEnter={() => setlogoutBuutonIsHovered(true)}
                    onMouseLeave={() => setlogoutBuutonIsHovered(false)}
                    style={logoutButtonStyle}
                  >
                    Logout
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="headerbottombar"></div>
    </div>
  );
};

export default Navbar;
