interface SubheadingTitleProps {
    text: string; // Prop for the dynamic text
  }
  
  const SubheadingTitle: React.FC<SubheadingTitleProps> = ({ text }) => {
    return (
      <div className="grid-col flex-2">
        <h2 className="subheading-title">{text}</h2>
      </div>
    );
  };
  
  export default SubheadingTitle;