import classnames from "classnames";

/**
 * Renders a `button` element
 * [USWDS Reference ↗](https://designsystem.digital.gov/components/button/)
 */
function Button({ type = "button", loading = false, ...props }: ButtonProps) {
    const classes = classnames(
        "bg-primary  font-bold text-white py-2 rounded-lg px-2 w-40 mt-3",
        props.className,
        "usa-button",
        props.variation ? `usa-button--${props.variation}` : "",
        {
            "usa-button--inverse": props.inversed,
            // This is weird, but we need this so that the inversed styling
            // kicks in when the variation is unstyled
            "usa-button--outline": props.inversed && props.variation === "unstyled",
            "button-disabled": props.disabled || loading,
        }
    );

    return (
        <button
            className={classes}
            name={props.name}
            onClick={props.onClick}
            type={type}
            disabled={props.disabled || loading}
            style={{ cursor: props.disabled || loading ? 'not-allowed' : 'pointer' }}
        >
            {loading ? "Saving..." : props.children}
        </button>
    );
}

interface ButtonProps {
    children: React.ReactNode;
    /**
     * Additional classes to apply to the HTML element. Useful for adding
     * utility classes to control spacing.
     */
    className?: string;
    /** Apply the "inverse" style modifier */
    inversed?: boolean;
    name?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    variation?: "" | "accent-cool" | "outline" | "secondary" | "unstyled";
    disabled?: boolean;
    loading ?: boolean;
}

export default Button;
